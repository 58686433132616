import { useEffect, useState } from "react";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./TubeTable.css";

import { Col, Container, Form, Row } from "react-bootstrap";

import Toasts from "../../Toasts/Toasts";

import _dataTableFilter from "../../../utils/dataTableFilter"


const TubeTable = () => {
  const [dataTube, setDataTube] = useState([]);
  const [dataTubeById, setDataTubeById] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [filter, setFilter] = useState("");
  const[ dataFiltered,setDataFiltered] = useState([])

  // Datatable variables :
  const widthcommment = "150px";

  const [hideAction, setHideAction] = useState(false);
  const [hideComment, setHideComment] = useState(false);
  const [hideCaracTum, setHideCaracTum] = useState(false);
  const [hideCaracH, setHideCaracH] = useState(false);

  const [omitTest, setOmitTest] = useState(false)

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: "ALL"
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        backgroundColor: "grey",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const fetchTube = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/pdx/tube/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataTube(res.data);
        })
        .catch((err) => {
          console.log("No data tube", err);
        });
    };
    fetchTube();
  }, [useRefresh]);

  useEffect(()=>{
    if(filter){
      setDataFiltered(_dataTableFilter(dataTube, filter));
    }
    console.log("dataFiltered",dataFiltered)
  },[filter])

  // Catch data from selected tube
  const selectedTube = (id) => {
    setDataTubeById(dataTube.find((e) => e.id === id));
  };

  console.log(dataTube);

  // Construction des colonnes de la datatable
  const columns = [
    {
      name: "Tube",
      selector: (row) => row.emplacement_tube,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: "Box",
      selector: (row) => row.box,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: "Box pos.",
      selector: (row) => row.box_position,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: "Ech. Valide ",
      selector: (row) => {
        switch (row.ech_valide) {
          case 0:
            return "";
          case 1:
            return "OK";
          case 2:
            return "Non";
          default:
            return "Ok";
        }
      },
      center: true,
      sortable: true,
      width: "130px",
    },
    {
      name: "Sortie",
      selector: (row) => {
        switch (row.sortie) {
          case 0:
            return "";
          case 1:
            return "OK";
          case 2:
            return "Non";
          default:
            return "Non";
        }
      },
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Date congélation</div>,
      selector: (row) => row.date_congelation?.split("T")[0],
      type: "date",
      center: true,
      sortable: true,
      width: "130px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: "Type",
      selector: (row) =>  {
        row.type === undefined ? setOmitTest(true) : setOmitTest(false) 
        return row?.type?.toUpperCase() || ""
      },

      center: true,
      sortable: true,
      width: "100px",
      wrap: true,
      omit : omitTest
    },
    {
      name: "Utilisateur",
      selector: (row) => row?.date_greffe?.split("T")[0],
      center: true,
      sortable: true,
      width: "180px",
    },
    {
      name: "Commentaire",
      selector: (row) => row.num_donneur,
      center: true,
      sortable: true,
      width: "200px",
    },
  ];

  return (
    <>
      
        
        
          <Container>
            
              <div>
                {/* Toast */}
                <div className="toastsPosition">
                  <Toasts
                    showToasts={showToasts}
                    toggleshowToasts={toggleShowToasts}
                    contentToasts={contentToasts}
                    stylesToasts={stylesToasts}
                  />
                </div>
                <div className="container">
                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h2 className="title_page">LISTE DES TUBES</h2>
                    </div>
                  </div>
                </div>
                <Col>
                <input
                  type="text"
                  placeholder="Rechercher..."
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>

                <DataTable
                  pagination
                  // paginationRowsPerPageOptions={[100, 200, 500, "Tout"]}
                  paginationComponentOptions={paginationComponentOptions}
                  paginationTotalRows={100}
                  paginationDefaultPage={1}
                  keyField="id"
                  columns={columns}
                  data={(filter ? dataFiltered : dataTube)}
                  columnToggle
                  dense={false}
                  responsive={true}
                  striped
                  customStyles={customStyles}
                  //Couleur des lignes
                  rowStyle={{ backgroundColor: "red" }}
                  fixedHeader={true}
                />
              </div>
          </Container>
        
      
    </>
  );
};

export default TubeTable;
