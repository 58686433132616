import React, { useContext } from "react";
import { AuthContext } from "../../components/AppContext";
import PdxModelTable from "../../components/Tables/PdxModelTable/PdxModelTable";
import Home from "../Home";
import Profil from "../Profil";

const PdxModelList = (props) => {
  const auth = useContext(AuthContext);

  return (
    <>
    {/* Si user detecté */}
    {auth.uid !== null ? (
      auth?.role?.modele_pdx?.page.r === true ? (
        <div>
           
          <PdxModelTable />
          <br />
          <br />
        </div>
      ) : (
        <Home />
      )
    ) : (
      <div>
        {/* <Si pas d'user detecté : Redirect /profil /> */}
        <div>
          <Profil />
        </div>
      </div>
    )}
  </>
  );
};

export default PdxModelList;
