import React, { useEffect, useState } from "react";
import Routes from "./Routes";
import { AuthContext } from "./components/AppContext";
import axios from "axios";
import Profil from "./view/Profil";
import _getRole from "./utils/getRole";
// import { getUser } from "./actions/user.actions";
// import { useDispatch } from "react-redux";

const App = () => {
  const [uid, setUid] = useState(null);
  const [role, setRole] = useState(null);
  const [auth, setAuth] = useState({});

  const fetchToken = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}jwtid`,
      withCredentials: true,
    })
      .then((res) => {
        setUid(res.data);
        // fetchRole(res.data)
        // setRole( _getRole(uid));
      })
      .catch((err) => {
        // console.log(err);
        console.log("No token App.js", err);
      });
  };

  const fetchRole = async () => {

      // console.log("role fetch", role  );
      if (role === null && uid!==null) {
        const temp = await _getRole(uid);
      setRole(temp);
      }
      
    };

  useEffect(() => {
    

    fetchToken();
    fetchRole();
    setAuth({
      uid,
      role,
    });
  }, [uid,role]);

  return (
    <AuthContext.Provider value={auth}>
      <Routes />
    </AuthContext.Provider>
  );
};

export default App;
