import React, { useContext } from "react";
import { AuthContext } from "../../components/AppContext";
import MesureGraph from "../../components/Graph/MesureGraph/MesureGraph";
import Home from "../Home";

import Profil from "../Profil";

const GraphMesure = (props) => {
  const auth = useContext(AuthContext);
  
  return (
    <>
      {/* Si user detecté */}
      {auth.uid !== null ? (
        // auth?.role?.passage?.page.r === true ? (
          <div>
            <MesureGraph />
            <br />
            <br />
          </div>
        // ) : (
          // <Home />
        // )
      ) : (
        <div>
          {/* <Si pas d'user detecté : Redirect /profil /> */}
          <div>
            <Profil />
          </div>
        </div>
      )}
    </>
  );
};

export default GraphMesure;
