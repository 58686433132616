import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";


import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";


import "./ModalIndex.css";
import _getAllRef from "../../utils/getRef";

const ModalOct = (props) => {
  // eslint-disable-next-line
  const [protocole, setProtocole] = useState();
  const [passage, setPassage] = useState([]);
  const [emplacementTube, setEmplacementTube] = useState();
  const [boite, setBoite] = useState();
  const [positionBoite, setPositionBoite] = useState();
  const [valide, setValide] = useState();
  const [dateCongelation, setDateCongelation] = useState();
  const [status, setStatus] = useState();
  const [user, setUser] = useState();
  const [sortie, setSortie] = useState();
  const [dateSortie, setDateSortie] = useState();
  const [siteSortie, setSiteSortie] = useState();
  const [sortieExtTube, setSortieExtTube] = useState();

  const [destinataire, setDestinataire] = useState();
  const [req1, setReq1] = useState();
  const [req2, setReq2] = useState();
  const [commentaire, setCommentaire] = useState();

  const [refPassage, setRefPassage] = useState();
  const [refUser, setRefUser] = useState();

  const handleClose = () => props.setModalShow(false);

  useEffect(() => {
    getRef();
  }, []);

  const getRef = async () => {
    // Get all ref passage 
    _getAllRef("passage")
      .then((res) => {
        res.unshift({
          id: null,
          num_hote: "",
          niveau_passage: null,
        });
        setRefPassage(res);
      })
      .catch((err) => {
        console.log("Error getRef passage", err);
      });
      // Get all ref user
    _getAllRef("user")
      .then((res) => {
        res.unshift({
          id: null,
          user_name: " ",
          user_lastname: " ",
        });
        setRefUser(res);
      })
      .catch((err) => {
        console.log("Error getRef passage", err);
      });
  };


  // AXIOS

  const handleEditTube = async (e) => {
    let url = "";
    let method = "";
    const data = {
      emplacement_tube: emplacementTube,
      box: boite,
      ech_valide: valide,
      sortie: sortie,
      date_congelation: dateCongelation,
      date_sortie: dateSortie,
      sortie_ext_tube: sortieExtTube,
      site_sortie_tube: siteSortie,
      dest_sortie_tube: destinataire,
      status_tube: status,
      req1: req1,
      req2: req2,
      commentaire: commentaire,
      id_user: user,
      // id_modele_pdx: modelePdx[0]?.id || "",
      id_passage: passage[0]?.id,
      id_protocole: protocole,
      type: "oct",
    };
    let contentToasts = "";

    switch (props.scope) {
      case "edit":
        url = `${process.env.REACT_APP_API_URL}api/pdx/tube/update/${props.dataTubeById.id}`;
        method = "put";
        contentToasts = "Tube mis à jour";

        props.setStylesToasts("info");
        break;

      case "add":
        url = `${process.env.REACT_APP_API_URL}api/pdx/tube/add`;
        method = "post";
        contentToasts = "Tube ajouté";
        props.setStylesToasts("success");

        break;

      default:
        contentToasts = "ERREUR";
        props.setStylesToasts("danger");
        break;
    }
    e.preventDefault();

    await axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
    })
      .then(() => {
        props.setContentToasts(contentToasts);
        console.log("toats", contentToasts);
        props.toggleShowToasts();
        props.setUseRefresh(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.scope === "edit"
              ? `Tube OCT ${props.dataTubeById?.Passage?.num_hote}`
              : "Ajout OCT"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Container>
            <Form>
              <Col xs={12} md={12}>
                <Row>
                  <Col xs={6} md={2}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Emplacement</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={
                          props?.dataTubeById?.emplacement_tube || ""
                        }
                        onChange={(e) => setEmplacementTube(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={2}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Boîte</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={props?.dataTubeById?.box || ""}
                        onChange={(e) => setBoite(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={2}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Tube valide</Form.Label>
                      <Form.Select
                        onChange={(e) => {
                          setValide(e.target.value);
                          console.log(typeof e.target.selectedIndex);
                        }}
                        autoFocus
                        defaultValue={+props?.dataTubeById?.ech_valide || null}
                      >
                        <option value={0}></option>
                        <option value={1}>Oui</option>
                        <option value={2}>Non</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date Congelation</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder=""
                        defaultValue={
                          props?.dataTubeById?.date_congelation?.split("T")[0] || ""
                        }
                        onChange={(e) => setDateCongelation(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={5} md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Passage</Form.Label>

                      <Typeahead
                        style={{ margin: "0 px" }}
                        id="basic-example"
                        maxResults={10}
                        defaultInputValue={
                          props?.dataTubeById?.Passage?.num_hote
                        }
                        emptyLabel="No Passage"
                        selected={passage}
                        onChange={(e) => {
                          setPassage(e);
                        }}
                        options={refPassage}
                        placeholder=""
                        labelKey="num_hote"
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Status</Form.Label>
                      <Form.Select
                        onChange={(e) => setStatus(e)}
                        autoFocus
                      >
                        <option>{props?.dataTubeById?.status_tube}</option>
                        <option value="">Maintien PDX</option>
                        <option value="">Etude</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={5} md={5}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Utilisateur</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={
                          props?.dataTubeById?.id_user || ""
                        }
                        onChange={(e) => setUser(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6} md={2}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Sortie</Form.Label>
                      <Form.Select
                        onChange={(e) => setSortie(e.target.sortie)}
                        autoFocus
                      >
                        <option>{props?.dataTubeById?.status_greffe}</option>
                        <option value="true">Oui</option>
                        <option value="false">Non</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Date Sortie</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder=""
                        defaultValue={props?.dataTubeById?.date_sortie || ""}
                        onChange={(e) => setUser(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>

                  <Col xs={4} md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Site Sortie</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={
                          props?.dataTubeById?.site_sortie_tube || ""
                        }
                        onChange={(e) => setSiteSortie(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4} md={4}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Destinataire</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={props?.dataTubeById?.dest_sortie_tube}
                        onChange={(e) => setDestinataire(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <hr />
                <Row>                
                  
                </Row>
                <Row>
                  <Col xs={12} md={8}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Commentaire </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        defaultValue={props?.dataTubeById?.caract_hote || ""}
                        onChange={(e) => setCommentaire(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={8}></Col>
                </Row>
              </Col>

              {props?.scope === "add" ? (
                <div>
                  <span className="textObligatoire">
                    Les champs avec astérisque (*) sont obligatoires.
                  </span>
                </div>
              ) : (
                ""
              )}
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="pe-2">
            <Button variant="success" onClick={handleEditTube}>
              Enregistrer
            </Button>
          </div>
          <div className="ps-2">
            <Button variant="danger" onClick={handleClose}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalOct;
