import React, { useContext } from "react";
import { AuthContext } from "../../components/AppContext";


import OctTable from "../../components/Tables/OctTable/OctTable";
import Home from "../Home";
import Profil from "../Profil";

const Oct = (props) => {
  const auth = useContext(AuthContext);
  
  return (
    <div>
      {/* Si user detecté */}
      {auth.uid !== null ? (
        auth?.role?.oct?.page?.r === true ? (
          <div>
            <OctTable />
            <br />
            <br />
          </div>
        ) : (
          <Home />
        )
      ) : (
        <div>
          {/* <Si pas d'user detecté : Redirect /profil /> */}
          <div>
            <Profil />
          </div>
        </div>
      )}
    </div>
  );
};

export default Oct;
