import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Toasts from "../Toasts/Toasts";

import _getAllRef from "../../utils/getRef";
import { role_format } from "../../role";

import "./ModalIndex.css";

const ModalRole = (props) => {
  const [name, setName] = useState();
  const [exclude, setExclude] = useState();
  // const [data, setData] = useState();
  let roleData = ""
  {props?.scope === "add" ? roleData = role_format : roleData = props.dataRoleById?.excludeParse}
  console.log("roleData", props?.scope, roleData)

  let temp = {};
  let titi = [];

  const handleClose = () => props.setModalShow(false);

  //Format Dates

  //Format Dates
  const current = new Date();
  const date = `${current.getFullYear()}/${
    current.getMonth() + 1
  }/${current.getDate()}`;

  useEffect(() => {
    
    //   excludeField = JSON.parse(props?.dataRoleById?.exclude_field);
  }, []);

  // const parseRoleData = () => {
  //   console.log("rrrr")
  //   if (props?.dataRoleById?.exclude_field) {
  //     temp = JSON.parse(props?.dataRoleById?.exclude_field);
  //     console.log("temp ecriture", temp.modele_pdx?.ref_modele_pdx_icm);
  //   }
  // };

  const handleSelectAllEdit = e => {
    props.dataRoleById.excludeParse.modele_pdx.page.w = true
    props.dataRoleById.excludeParse.tube.page.w = true
  };

  // Converting role data to JSON
  const stringifyRoleData = async (data) => {
    return JSON.stringify(data);
  };



  // AXIOS

  const handleEditRole = async (e) => {
    const tata = await stringifyRoleData(roleData);
    const data = {
      nom_role: name,
      exclude_field: tata,
    };
    let url = "";
    let method = "";
    let contentToasts = "";
    switch (props.scope) {
      case "edit":
        url = `${process.env.REACT_APP_API_URL}api/user/role/update/${props.dataRoleById.id}`;
        method = "put";
        contentToasts = "Rôle mis à jour";
        props.setStylesToasts("info");
        break;

      case "add":
        url = `${process.env.REACT_APP_API_URL}api/user/role/add`;
        method = "post";
        contentToasts = "Rôle ajouté";
        props.setStylesToasts("success");

        break;

      default:
        contentToasts = "ERREUR";
        props.setStylesToasts("danger");
        break;
    }
    e.preventDefault();

    await axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
    })
      .then(() => {
        props.setContentToasts(contentToasts);
        props.toggleShowToasts();
        props.setUseRefresh(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.scope === "edit"
              ? `Role ${props?.dataRoleById?.nom_role}`
              : "Ajouter un role"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Container>
            <Form>
              <Row>
                <Col lg={3}>
                  <Form.Control
                    size="sm"
                    type="text"
                    placeholder="Nom du role"
                    defaultValue={props?.dataRoleById?.nom_role || ""}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col></Col>
                <Col>
                  <span>Lecture</span>
                </Col>
                <Col>
                  <span>Ecriture</span>
                </Col>
                <Col>
                  <span>Suppression</span>
                </Col>
              </Row>
              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page Modele PDX</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.modele_pdx?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.modele_pdx.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.modele_pdx?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.modele_pdx.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.modele_pdx?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.modele_pdx.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>

              {/* 1ere colonne */}
              <Row>
                &emsp;<Col>Colonne Ref</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.modele_pdx
                            ?.ref_modele_pdx_icm?.lecture
                        : true
                    }
                    onChange={(e) => {
                      roleData.modele_pdx.ref_modele_pdx_icm.lecture =
                        e.target.checked;
                      console.log(roleData);
                    }}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>

              {/* 2e colonne */}
              <Row>
                &emsp;<Col>Colonne Date Greffe</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.modele_pdx
                            ?.date_greffe_modele_pdx?.lecture
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.modele_pdx.date_greffe_modele_pdx.lecture =
                        e.target.checked;
                    }}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              {/* 3e colonne */}
              <Row>
                &emsp;<Col>Colonne Nb Passage</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.modele_pdx
                            ?.count_passage?.lecture
                        : true
                    }
                    onChange={(e) => {
                      roleData.modele_pdx.count_passage.lecture =
                        e.target.checked;
                    }}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page Tube</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.tube?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      roleData.tube.page.r = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  {/* <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.tube?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.tube.page.w = e.target.checked;
                    }}
                  /> */}
                </Col>
                <Col>
                  {/* <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.tube?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.tube.page.d = e.target.checked;
                    }}
                  /> */}
                </Col>
              </Row>

              {/* 1ere ligne */}
              <Row>
                &emsp;<Col>Emplacement tube</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.tube
                            ?.emplacement_tube?.lecture
                        : true
                    }
                    onChange={(e) => {
                      roleData.tube.emplacement_tube.lecture = e.target.checked;
                      console.log(roleData);
                    }}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              {/* 2e ligne */}
              <Row>
                &emsp;<Col>Boite tube</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.tube?.box?.lecture
                        : true
                    }
                    onChange={(e) => {
                      roleData.tube.box.lecture = e.target.checked;
                    }}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              {/* 3e ligne */}
              <Row>
                &emsp;<Col>Position boite</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.tube?.box_position
                            ?.lecture
                        : true
                    }
                    onChange={(e) => {
                      roleData.tube.box_position.lecture = e.target.checked;
                    }}
                  />
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page Passages</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.passage?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.passage.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.passage?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.passage.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.passage?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.passage.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>

              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page DMSO</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.dmso?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.dmso.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.dmso?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.dmso.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.dmso?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.dmso.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>

              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page DRY</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.dry?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.dry.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.dry?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.dry.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.dry?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.dry.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>
              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page OCT</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.oct?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.oct.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.oct?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.oct.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.oct?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.oct.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>
              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page PFA</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.pfa?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.pfa.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.pfa?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.pfa.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.pfa?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.pfa.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>
              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page Mesures</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.mesure?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.mesure.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.mesure?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.mesure.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.mesure?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.mesure.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>

              <hr />
              {/* Ligne Titre */}
              <Row>
                <Col>
                  <span className="fw-bold">Page Expérimentation</span>
                </Col>
                &emsp;
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.experimentation?.page?.r
                        : true
                    }
                    onChange={(e) => {
                      console.log("scope", props.scope);
                      roleData.experimentation.page.r = e.target.checked;
                      console.log("check", e.target.checked);
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.experimentation?.page?.w
                        : true
                    }
                    onChange={(e) => {
                      roleData.experimentation.page.w = e.target.checked;
                    }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.experimentation?.page?.d
                        : true
                    }
                    onChange={(e) => {
                      roleData.experimentation.page.d = e.target.checked;
                    }}
                  />
                </Col>
              </Row>

              <hr />
              {/* Admin */}
              <Row>
                &emsp;<Col>Admin</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.global?.admin
                        : true
                    }
                    onChange={(e) => {
                      roleData.global.admin = e.target.checked;
                      console.log(roleData);
                    }}
                  />
                </Col>
                <Col></Col>
              </Row>
              {/* Modification */}
              <Row>
                &emsp;<Col>Modification</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.global?.edit
                        : true
                    }
                    onChange={(e) => {
                      roleData.global.edit = e.target.checked;
                      // console.log("AllEdit");
                      // handleSelectAllEdit();
                    }}
                  />
                </Col>
                <Col></Col>
              </Row>
              {/* Admin */}
              <Row>
                &emsp;<Col>Suppression</Col>
                <Col>
                  <Form.Check
                    className={""}
                    type="checkbox"
                    rows={8}
                    defaultChecked={
                      props.scope === "edit"
                        ? props?.dataRoleById?.excludeParse?.global?.delete
                        : true
                    }
                    onChange={(e) => {
                      console.log(
                        "coché?",
                        props?.dataRoleById?.excludeParse?.global?.delete
                      );
                      roleData.global.delete = e.target.checked;
                      console.log(roleData);
                    }}
                  />
                </Col>
                <Col></Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="pe-2">
            <Button variant="success" onClick={handleEditRole}>
              Enregistrer
            </Button>
          </div>
          <div className="ps-2">
            <Button variant="danger" onClick={handleClose}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalRole;
