import React, { useState } from "react";
import axios from "axios";
import { Button, Form } from "react-bootstrap";

// CSS
import "./index.css";

const SignInForm = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  // Active le bouton Login si les champs Login et Password sont remplis
  function validateForm() {
    return login.length > 0 && password.length > 0;
  }

  const handleLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const loginError = document.querySelector("#loginError");
    const passwordError = document.querySelector(".password.error");
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/user/login`,
      withCredentials: true,
      data: {
        login,
        password,
      },
    })
      .then((res) => {
        if (res.data.errors) {
          loginError.innerHTML = res.data.errors.login;
          passwordError.innerHTML = res.data.errors.password;
        } else {
          // Si connexion réussie, renvoie vers une page
          window.location = "/home";
        }
      })

      .catch((err) => {
        document.getElementById("errorlogin").innerHTML =
          "Identifiant ou mot de passe incorrect";
      });
  };

  return (
    <div className="LogForm">
      <Form action="" onSubmit={handleLogin} id="sign-up-form">
        <Form.Group size="lg" controlId="login">
          <Form.Label>Identifiant</Form.Label>
          <Form.Control
            autoFocus
            type="text"
            onChange={(e) => setLogin(e.target.value)}
            value={login}
          />
        </Form.Group>
        <div className="login error"></div>
        <Form.Group size="lg" controlId="password">
          <Form.Label className="mt-3">Mot de passe</Form.Label>
          <Form.Control
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </Form.Group>
        <div className="password error" id="errorlogin"></div>
        <br />
        <Button
          className="m-5"
          id="btn-login"
          block="true"
          size="lg"
          type="submit"
          disabled={!validateForm()}
        >
          Se connecter
        </Button>
      </Form>
      <br />
    </div>
  );
};

export default SignInForm;
