import * as XLSX from "xlsx";

const _exportData = (data) => {
  const dataToExport = [];

  const extractData = (obj, newObj) => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        extractData(obj[key], newObj);
      } else {
        newObj[key] = obj[key];
      }
    }
  };

  data.forEach((item) => {
    const newItem = {};
    extractData(item, newItem);
    dataToExport.push(newItem);
  });

  const ws = XLSX.utils.json_to_sheet(dataToExport);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  XLSX.writeFile(wb, "data.xlsx");
};

export default _exportData;
