import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../components/AppContext";

import TubeTable from "../../components/Tables/TubeTable/TubeTable";
import _getRole from "../../utils/getRole";
import Home from "../Home";
import Profil from "../Profil";

const Tube = (props) => {
  const auth = useContext(AuthContext);
  const [role, setRole] = useState();

  useEffect(() => {
    
  }, []);

  // console.log(role);
  return (
    <div>
      {/* Si user detecté */}
      {auth.uid !== null ? (
        auth?.role?.tube?.page?.r === true ? (
          <div>
            <TubeTable />
            <br />
            <br />
          </div>
        ) : (
          <Home />
        )
      ) : (
        <div>
          {/* <Si pas d'user detecté : Redirect /profil /> */}
          <div>
            <Profil />
          </div>
        </div>
      )}
    </div>
  );
};

export default Tube;
