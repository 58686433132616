import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./ChirurgieTable.css";

import { Col, Container, Row } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";

import ModalDmso from "../../Modal/ModalDmso";

import Toasts from "../../Toasts/Toasts";
import { BsCheckCircleFill } from "react-icons/bs";
import _dataTableFilter from "../../../utils/dataTableFilter";

const ChirurgieTable = () => {
  // Get user role to manage his rights and access
  let role = "";
  const auth = useContext(AuthContext);
  if (auth) {
    role = auth?.role?.dmso;
  }

  const [dataChirurgie, setDataChirurgie] = useState([]);
  const [dataChirurgieById, setDataChirurgieById] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [filter, setFilter] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);

  const [hideAction, setHideAction] = useState(false);
  const [hideCaracH, setHideCaracH] = useState(false);

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        backgroundColor: "grey",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const fetchChirurgie = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/pdx/chirurgie/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataChirurgie(res.data);
        })
        .catch((err) => {
          console.log("No data chirurgie", err);
        });
    };
    fetchChirurgie();
    setDataChirurgie(data);
  }, [useRefresh]);

  // Appel vers la fonction de recherche
  useEffect(() => {
    if (filter) {
      setDataFiltered(_dataTableFilter(dataChirurgie, filter));
    }
    console.log("dataFiltered", dataFiltered);
  }, [filter, dataFiltered]);

  // Catch data from selected passage
  const selectedChirurgie = (id) => {
    setDataChirurgieById(dataChirurgie.find((e) => e.id === id));
  };

  console.log(dataChirurgie);
  const data = [
    {
      box: "",
      box_position: "",
      commentaire: null,
      createdAt: "",
      date_congelation: "",
      date_sortie: null,
      date_traitement: null,
      dest_sortie_tube: null,
      ech_valide: "",
      emplacement_tube: "",
      id_passage: 67,
      id_user: 1,
      manipulateur: null,
      req1: "",
      req2: "",
      req3: null,
      site_sortie_tube: "",
      sortie: "",
      sortie_ext_tube: null,
      status_tube: "",
      type: "",
    },
  ];

  // Construction des colonnes de la datatable
  const columns = [
    {
      selector: (row) => (
        <div>
          {/* Hide action column if user have not the autorisation */}
          {role?.page?.w === false && role?.page?.d === false
            ? setHideAction(true)
            : setHideAction(false)}
          <Row>
            {role?.page?.w === true ? (
              <Col
                variant="light"
                className="btnTable pe-3"
                onClick={(e) => {
                  e.preventDefault();
                  selectedChirurgie(row.id);
                  setModalShow(true);
                }}
              >
                <PencilSquare />
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      ),
      name: "Action",
      button: true,
      width: "100px",
      center: true,
      omit: hideAction,
    },
    {
      name: <div>Anapath</div>,
      selector: (row) => row.num_anapath,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>BCB</div>,
      selector: (row) => row.num_bcb,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Date chirurgie</div>,
      selector: (row) => row?.date_chirurgie?.split("T")[0],
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Heure chirurgie</div>,
      selector: (row) => row?.heure_chirurgie,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Heure reception CRB</div>,
      selector: (row) => row.heure_recpetion_crb,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Primitive</div>,
      selector: (row) => {
        return <div>{row.primitive === true ? <BsCheckCircleFill /> : ""}</div>;
      },
      center: true,
      sortable: true,
      width: "100px",
      wrap: true,
      omit: hideCaracH,
    },
    {
      name: <div>Type tumeur</div>,
      selector: (row) => row.type_tumeur,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Site prélèvement</div>,
      selector: (row) => row.site_prelevement,
      center: true,
      sortable: true,
      width: "120px",
    },
    {
      name: <div>Type histo</div>,
      selector: (row) => row.type_histo,
      center: true,
      sortable: true,
      width: "110px",
    },
    {
      name: <div>pT</div>,
      selector: (row) => row.pt,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>pN</div>,
      selector: (row) => row.pn,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>pM</div>,
      selector: (row) => row.pm,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Grade</div>,
      selector: (row) => row.grade,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Stade</div>,
      selector: (row) => row.stade,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Meta ganglionnaires</div>,
      selector: (row) => {
        return (
          <div>
            {row.meta_ganglionnaires === true ||
            row.meta_ganglionnaires === "oui" ? (
              <BsCheckCircleFill />
            ) : (
              ""
            )}
          </div>
        );
      },
      center: true,
      sortable: true,
      width: "130px",
    },
    {
      name: <div>Ganglions envahis</div>,
      selector: (row) => row.ganglions_envahis,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Ganglions prélevés</div>,
      selector: (row) => row.ganglions_preleves,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>MSI</div>,
      selector: (row) => row.msi,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>HER2</div>,
      selector: (row) => row.her2,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>ER</div>,
      selector: (row) => row.er,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>PR</div>,
      selector: (row) => row.pr,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>K167</div>,
      selector: (row) => row.k167,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>CK</div>,
      selector: (row) => row.ck,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>MDM2</div>,
      selector: (row) => row.mdm2,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>KRAS</div>,
      selector: (row) => row.kras,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>EMA</div>,
      selector: (row) => row.ema,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>CD34</div>,
      selector: (row) => row.cd34,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>PS100</div>,
      selector: (row) => row.ps100,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>ANC</div>,
      selector: (row) => row.anc,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>Desmine</div>,
      selector: (row) => row.desmine,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>ATM</div>,
      selector: (row) => row.atm,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>PIK3CA</div>,
      selector: (row) => row.pik3ca,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>BRAF</div>,
      selector: (row) => row.braf,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>CDNK2</div>,
      selector: (row) => row.cdnk2,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>CTNNB1</div>,
      selector: (row) => row.ctnnb1,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>APC</div>,
      selector: (row) => row.apc,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>Commentaire</div>,
      selector: (row) => row.commentaire,
      center: true,
      sortable: true,
      width: "150px",
    },
  ];

  return (
    <>
      <Container fluid>
        {auth ? (
          <div>
            {/* Toast */}
            <div className="toastsPosition">
              <Toasts
                showToasts={showToasts}
                toggleshowToasts={toggleShowToasts}
                contentToasts={contentToasts}
                stylesToasts={stylesToasts}
              />
            </div>
            <div className="container">
              <div className="row">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className="title_page">LISTE ANAPATH</h2>
                </div>
              </div>
            </div>
            {/* Champ de recherche */}
            <Col>
              <input
                type="text"
                placeholder="Rechercher..."
                onChange={(e) => setFilter(e.target.value)}
              />
            </Col>

            {/* Tableau de données */}
            <DataTable
              pagination
              paginationRowsPerPageOptions={[50, 100, 200]}
              keyField="id"
              columns={columns}
              data={filter ? dataFiltered : dataChirurgie}
              columnToggle
              dense={false}
              responsive={true}
              striped
              customStyles={customStyles}
              //Couleur des lignes
              rowStyle={{ backgroundColor: "red" }}
              fixedHeader={true}
            />
            {/* Appel du modal d'ajout et édition */} 
            <ModalDmso
              dataTubeById={dataChirurgieById}
              setModalShow={setModalShow}
              modalShow={modalShow}
              setUseRefresh={setUseRefresh}
              scope={"edit"}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            />
          </div>
        ) : (
          <div>
            <Loading titleSpinner="Loading data" />
          </div>
        )}
      </Container>
    </>
  );
};

export default ChirurgieTable;
