import React from "react";
import Log from "../components/Log";

import { useContext } from "react";
import { AuthContext } from "../components/AppContext";
import Home from "./Home";

const Profil = () => {
  const auth = useContext(AuthContext);
  return (
    <div className="profil-page">
      {auth.uid !== null ? (
        // Si l'utilisateur est connecté avec un token, renvoie vers la page d'accueil
        <div>
          <Home />
        </div>
      ) : (
        <div className="log-container">
          <Log />
        </div>
      )}
    </div>
  );
};

export default Profil;
