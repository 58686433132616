import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import "./ModalIndex.css";
import _getAllRef from "../../utils/getRef";

const ModalMesure = (props) => {
  // eslint-disable-next-line
  if (props.dataMesureById) {
    console.log("DATA", props.dataMesureById[0].date_mesure);
  }
  // const [dataAdd, setDataAdd] = useState()
  let tempsArray = [];

  const [dateMesure, setDateMesure] = useState();
  const [refPassage, setRefPassage] = useState();
  const [refUser, setRefUser] = useState();
  const [curentPassage, setCurrentPassage] = useState([]);
  const [hote, setHote] = useState();
  const [dA, setdA] = useState("");
  const [dB, setdB] = useState("");
  const [poids, setPoids] = useState("");
  const [passage, setPassage] = useState([]);

  const handleClose = () => props.setModalShow(false);

  useEffect(() => {
    getRef();
    getCurrent();
    if (props?.scope == "add") {
      buildFirst();
    }
    console.log("props dataMesureID", props.dataMesureById);
  }, []);

  const getRef = async () => {
    // Get all ref passage
    _getAllRef("passage")
      .then((res) => {
        res.unshift({
          id: null,
          num_hote: "",
          niveau_passage: null,
        });
        // console.log(res);
        setRefPassage(res);
      })
      .catch((err) => {
        console.log("Error getRef passage", err);
      });
  };

  // console.log(refPassage);

  const getCurrent = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/pdx/passage/listCurrent`,
      withCredentials: true,
    })
      .then((res) => {
        setCurrentPassage(res.data);
        console.log("current", res.data);
      })
      .catch((err) => {
        console.log("No data passage", err);
      });
  };

  //Format Dates
  const curr = new Date();
  const date = curr.toISOString().substring(0, 10);
  // console.log("date", date);

  // AXIOS

  const handleEditTube = async (e) => {
    let url = "";
    let method = "";
    const data = {
      date_mesure: dateMesure,
      diametre_a: dA,
      diametre_b: dB,
      poids: poids,
      id_passage: passage,
    };
    let contentToasts = "";

    switch (props.scope) {
      case "edit":
        url = `${process.env.REACT_APP_API_URL}api/pdx/mesure/update/${props.dataTubeById.id}`;
        method = "put";
        contentToasts = "Mesures mises à jour";

        // props.setStylesToasts("info");
        break;

      case "add":
        url = `${process.env.REACT_APP_API_URL}api/pdx/mesure/add`;
        method = "post";
        contentToasts = "Session de mesure ajoutée";
        // props.setStylesToasts("success");

        break;

      default:
        contentToasts = "ERREUR";
        // props.setStylesToasts("danger");
        break;
    }
    e.preventDefault();

    await axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
    })
      .then(() => {
        props.setContentToasts(contentToasts);
        console.log("toats", contentToasts);
        props.toggleShowToasts();
        props.setUseRefresh(true);
        // Destruction of temp data
        tempsArray = [];
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleAddMesure2 = async (e) => {
    console.log(tempsArray);
  };

  const handleAddMesure = async (e) => {
    let url = "";
    let method = "";
    let contentToasts = "";

    url = `${process.env.REACT_APP_API_URL}api/pdx/mesure/add`;
    method = "post";
    contentToasts = "Session de mesure ajoutée";
    props.setStylesToasts("success");

    e.preventDefault();

    await axios({
      method: method,
      url: url,
      data: tempsArray,
      withCredentials: true,
    })
      .then(() => {
        props.setContentToasts(contentToasts);
        console.log("toats", contentToasts);
        props.toggleShowToasts();
        props?.setUseRefresh(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  for (let index in curentPassage) {
    tempsArray.push({
      id_passage: curentPassage[index].id,
      diametre_a: null,
      diametre_b: null,
      poids: null,
      date_mesure: date,
    });
  }

  const buildFirst = () => {};

  // function qui créer mon tableau
  const builArrayValue = (field, id, value, index) => {
    // si la date choisie change, on met a jour sur tout le tableau
    if (field === "date_mesure") {
      console.log("date", value);
      for (let i in tempsArray) {
        tempsArray[i][field] = value;
      }
    } else {
      // sinon on remplace la data modifié au bon endroit
      tempsArray[index][field] = value;
    }
  };

  return (
    <div>
      <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.scope === "edit"
              ? `Mesures du ${new Date(
                  props.dataMesureById[0]?.date_mesure
                ).toLocaleDateString("fr-FR")}`
              : "Ajout d'une session de mesure"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Row>
            {/* Champ de sélection de la date */}
            <Col xs={6} md={3}>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                  type="date"
                  placeholder=""
                  defaultValue={
                    props.dataMesureById
                      ? props.dataMesureById[0].date_mesure.split("T")[0]
                      : date
                  }
                  onChange={(v) =>
                    builArrayValue("date_mesure", "", v.target.value, "")
                  }
                  autoFocus
                />
              </Form.Group>
            </Col>
            {/* Personne qui a réalisé la session de mesure */}
            <Col xs={6} md={3}>
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Mesureur"
                  defaultValue={
                    props.dataMesureById
                      ? props.dataMesureById?.mesureur
                      : "Lise Fenou"
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          {props?.scope === "edit" ? (
            <Container>
              <Row className="text-center">
                <Col xs={12} md={1}>
                  <span>Hote </span>
                </Col>
                <Col>
                  <span>diamètre A (mm)</span>
                </Col>
                <Col>
                  <span>diamètre B (mm)</span>
                </Col>
                <Col>
                  <span>
                    Poids
                    <br />
                    (g)
                  </span>
                </Col>
                <Col>
                  <span>Vtumoral (mm³)</span>
                </Col>
                <Col>
                  <span>Evo poids initial</span>
                </Col>
                <Col>
                  <span>Evo poids précédent</span>
                </Col>
              </Row>
              {props.dataMesureById
                ? props.dataMesureById.map((e) => {
                    // Conditionnal style vTum
                    let vTum = (e.diametre_a * e.diametre_a * e.diametre_b) / 2;
                    vTum = vTum.toFixed(2);
                    let vTumStyle = "text-center";
                    if (vTum >= 1500) {
                      vTumStyle += " bg-danger text-white";
                    }
                    if (vTum < 1500 && vTum >= 1200) {
                      vTumStyle += " bg-warning text-white";
                    }
                    if (vTum < 1200 && vTum >= 800) {
                      vTumStyle += " bgYellow";
                    }

                    // Calcul de la différence avec le poids précédent
                    let diffPoidsPrec;
                    if(e?.poids_precedent){
                      diffPoidsPrec = ((e?.poids - e?.poids_precedent) / e?.poids) * 100;
                    diffPoidsPrec = diffPoidsPrec.toFixed(2);
                    } 
                    
                    let diffPoidsPrecStyle = "text-center";
                    let diffPoidsPrecStyleBold = false;

                    // Conditionnal style diffPoidsPrec
                    if (diffPoidsPrec < -19) {
                      diffPoidsPrecStyle += " bg-danger text-white";
                    }
                    if (diffPoidsPrec < -15 && diffPoidsPrec >= -19) {
                      diffPoidsPrecStyle += " bg-warning text-white";
                    }
                    if (diffPoidsPrec < -10 && diffPoidsPrec >= -15) {
                      diffPoidsPrecStyle += " bgYellow";
                    }
                    if (diffPoidsPrec < -7 && diffPoidsPrec >= -10) {
                      diffPoidsPrecStyleBold = { "font-weight": "bold" };
                    }

                    // Calcul de la différence avec le poids initial
                    let diffPoidsInit;
                    if(e?.poids_initial){
                      diffPoidsInit = ((e?.poids - e?.poids_initial) / e?.poids) * 100;
                    diffPoidsInit = diffPoidsInit.toFixed(2);
                    } 

                    console.log("vTumStyle", vTumStyle);
                    return (
                      <div>
                        <div key={e.id}>
                          <Row>
                            <Col xs={12} md={1}>
                              {e.Passage.num_hote}
                            </Col>
                            <Col>
                              <Form.Control
                                className="text-center"
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                defaultValue={e.diametre_a || ""}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className="text-center"
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                defaultValue={e.diametre_b || ""}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className="text-center"
                                key={e.id}
                                size="sm"
                                type="text"
                                color="success"
                                placeholder=""
                                defaultValue={e.poids || ""}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className={vTumStyle}
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                disabled
                                defaultValue={vTum || ""}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className="text-center"
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                disabled
                                defaultValue={diffPoidsInit?(diffPoidsInit + "%"):("")}
                              />
                            </Col>
                            <Col>
                              <Form.Control
                                className={diffPoidsPrecStyle}
                                // style={
                                //   diffPoidsPrecStyleBold
                                //   // ? { 'font-weight': 'bold' }
                                //   // : ""
                                // }
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                disabled
                                defaultValue={diffPoidsPrec?(diffPoidsPrec + "%"):("1e mesure")}
                              />
                            </Col>
                          </Row>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </Container>
          ) : (
            <Container>
              <Row>
                <Col></Col>
                <Col>
                  <span>Hote </span>
                </Col>
                <Col>
                  <span>dA (mm)</span>
                </Col>
                <Col></Col>
                <Col>
                  <span>dB (mm)</span>
                </Col>
                <Col></Col>
                <Col>
                  <span>Poids (g)</span>
                </Col>
                <Col></Col>
              </Row>
              {curentPassage
                ? curentPassage.map((e, index) => {
                    // {setHote(refPassage.find((f) => f.id_passage === refPassage.id))}
                    return (
                      <Row>
                        <div key={e.id}>
                          <Row>
                            <Col></Col>

                            <Col>{e.num_hote}</Col>

                            <Col>
                              <Form.Control
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                onChange={(v) =>
                                  builArrayValue(
                                    "diametre_a",
                                    e.id,
                                    v.target.value,
                                    index
                                  )
                                }
                              />
                            </Col>
                            <Col></Col>

                            <Col>
                              <Form.Control
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                onChange={(v) =>
                                  builArrayValue(
                                    "diametre_b",
                                    e.id,
                                    v.target.value,
                                    index
                                  )
                                }
                              />
                            </Col>
                            <Col></Col>
                            <Col>
                              <Form.Control
                                key={e.id}
                                size="sm"
                                type="text"
                                placeholder=""
                                onChange={(v) =>
                                  builArrayValue(
                                    "poids",
                                    e.id,
                                    v.target.value,
                                    index
                                  )
                                }
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                        </div>
                      </Row>
                    );
                  })
                : "Une erreur est survenue"}
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="pe-2">
            <Button variant="success" onClick={handleAddMesure}>
              Enregistrer
            </Button>
          </div>
          <div className="ps-2">
            <Button variant="danger" onClick={handleClose}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalMesure;
