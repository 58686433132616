import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../components/AppContext";
import { Form, ModalBody, ModalFooter } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import "./ModalIndex.css";

const ModalModelPdx = (props) => {
  // Get user role to manage his rights and access
  let role = "";
  const auth = useContext(AuthContext);
  if (auth) {
    role = auth?.role?.modele_pdx;
  }

  const [refModelPdx, setRefModelPdx] = useState();
  const [patient, setPatient] = useState();
  const [anapath, setAnapath] = useState();
  const [dateGreffe, setDateGreffe] = useState();
  const [passage, setPassage] = useState();

  const [anc, setAnc] = useState();
  const [cd34, setCd34] = useState();
  const [cytokeratine, setCytoketarine] = useState();
  const [desmine, setDesmine] = useState();
  const [ema, setEma] = useState();
  const [k167, setK167] = useState();

  const [mdm2, setMdm2] = useState();
  const [ps100, setPs100] = useState();
  const [tp53, setTp53] = useState();
  const [kras, setKras] = useState();
  const [atm, setAtm] = useState();
  const [brca1, setBrca1] = useState();
  const [tp53Mut, setTp53Mut] = useState();
  const [krasMut, setKrasMut] = useState();
  const [atmMut, setAtmMut] = useState();
  const [brca1Mut, setBrca1Mut] = useState();

  const [commentaire, setCommentaire] = useState();

  const handleClose = () => props.setModalShow(false);

  //Format Dates

  // if (props?.dataModelPdxById?.date_greffe) {
  //   props.dataModelPdxById.date_greffe =
  //     props.dataModelPdxById.date_greffe.split("T")[0];
  // }

  // if (props?.dataModelPdxById?.date_deces) {
  //   props.dataModelPdxById.date_deces =
  //     props.dataModelPdxById.date_deces.split("T")[0];
  // }

  // if (props?.dataModelPdxById?.date_arrivee) {
  //   props.dataModelPdxById.date_arrivee =
  //     props.dataModelPdxById.date_arrivee.split("T")[0];
  // }

  //Format Dates
  const current = new Date();
  const date = `${current.getFullYear()}/${
    current.getMonth() + 1
  }/${current.getDate()}`;

  // console.log("props", props);

  useEffect(() => {}, [atm, tp53, kras, brca1]);

  // AXIOS
  const handleEdit = async (e) => {
    let url = "";
    let method = "";
    const data = {
      uid: auth.uid,
      ref_modele_pdx_icm: refModelPdx,
      num_patient: patient,
      num_anapath: anapath,
      niveau_passage: passage,
      date_greffe_modele_pdx: dateGreffe,
      anc_status: anc,
      cd34_status: cd34,
      cytokeratine: cytokeratine,
      desmine_status: desmine,
      ema_status: ema,
      k167_status: k167,
      mdm2_status: mdm2,
      ps100_status: ps100,
      tp53_status: tp53,
      atm_status: atm,
      kras_status: kras,
      brca1_status: brca1,
      tp53_mutation: tp53Mut,
      atm_mutation: atmMut,
      kras_mutation: krasMut,
      braca1_mutation: brca1Mut,
      commentaire_modele_pdx: commentaire,
    };
    let contentToasts = "";

    switch (props.scope) {
      case "edit":
        url = `${process.env.REACT_APP_API_URL}api/pdx/modele_pdx/update/${props.dataModelPdxById.id}`;
        method = "put";
        contentToasts = "Modèle mis à jour";
        props.setStylesToasts("info");
        break;

      case "add":
        url = `${process.env.REACT_APP_API_URL}api/pdx/modele_pdx/add`;
        method = "post";
        contentToasts = "Modèle ajouté";
        props.setStylesToasts("success");

        break;

      default:
        contentToasts = "ERREUR";
        props.setStylesToasts("danger");
        break;
    }
    e.preventDefault();

    await axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
    })
      .then(() => {
        props.setContentToasts(contentToasts);
        console.log("toats", contentToasts);
        props.toggleShowToasts();
        props.setUseRefresh(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.scope === "edit"
              ? `Passage ${props.dataModelPdxById.ref_modele_pdx_icm}`
              : "Ajout Modele PDX"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Container>
            <Form>
              <Row>
                {/* Left column */}
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          REF
                          {/* Asterisk for required input for add passage*/}
                          {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue={
                            props?.dataModelPdxById?.ref_modele_pdx_icm || ""
                          }
                          onChange={(e) => setRefModelPdx(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Patient</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          // defaultValue={
                          //   props?.dataModelPdxById?.num_donneur || ""
                          // }
                          defaultValue={
                            props?.dataModelPdxById?.ref_modele_pdx_icm || ""
                          }
                          onChange={(e) => setPatient(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Anapath</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          // defaultValue={
                          //   props?.dataModelPdxById?.num_patient || ""
                          // }
                          defaultValue={
                            props?.dataModelPdxById?.ref_modele_pdx_icm || ""
                          }
                          onChange={(e) => setPatient(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>

                {/* Right column */}
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={6} md={4}>
                      {props?.scope === "edit" ? (
                        <div>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Nb. Passage</Form.Label>

                            <Form.Control
                              disabled={true}
                              type="text"
                              placeholder=""
                              defaultValue={
                                props?.dataModelPdxById?.count_passage || ""
                              }
                              autoFocus
                            />
                          </Form.Group>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={6} md={5}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Date Greffe</Form.Label>
                        <Form.Control
                          type="date"
                          defaultValue={
                            props?.dataModelPdxById?.date_greffe_modele_pdx?.split("T")[0] || ""
                          }
                          onChange={(e) => setDateGreffe(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                {/* 1e colonne de gènes */}
                <Col xs={12} md={3}>
                  <br />
                  <Form.Check
                    label={"MDM2"}
                    className={"pull-right"}
                    type="checkbox"
                    rows={1}
                    defaultChecked={props?.dataModelPdxById?.mdm2_status || ""}
                    onChange={(e) => setMdm2(e.target.checked)}
                  />
                  <Form.Check
                    label={"ANC"}
                    className={"pull-right"}
                    type="checkbox"
                    rows={1}
                    placeholder=""
                    defaultChecked={props?.dataModelPdxById?.anc_status || ""}
                    onChange={(e) => setAnc(e.target.checked)}
                  />
                  <Form.Check
                    label={"KI67"}
                    className={"pull-right"}
                    type="checkbox"
                    rows={1}
                    placeholder=""
                    defaultChecked={props?.dataModelPdxById?.k167_status || ""}
                    onChange={(e) => setK167(e.target.checked)}
                  />
                  <Form.Check
                    label={"EMA"}
                    className={"pull-right"}
                    type="checkbox"
                    rows={1}
                    placeholder=""
                    defaultChecked={props?.dataModelPdxById?.ema_status || ""}
                    onChange={(e) => setEma(e.target.checked)}
                  />
                </Col>
                {/* 2e colonne de gènes */}
                <Col xs={12} md={3}>
                  <br />
                  <Form.Check
                    label={"CD34"}
                    className={"pull-right"}
                    type="checkbox"
                    rows={1}
                    placeholder=""
                    defaultChecked={props?.dataModelPdxById?.cd34_status || ""}
                    onChange={(e) => setCd34(e.target.checked)}
                  />
                  <Form.Check
                    label={"CK"}
                    className={"pull-right"}
                    type="checkbox"
                    rows={1}
                    placeholder=""
                    defaultChecked={props?.dataModelPdxById?.cytokeratine || ""}
                    onChange={(e) => setCytoketarine(e.target.checked)}
                  />
                  <Form.Check
                    label={"PS100"}
                    className={"pull-right"}
                    type="checkbox"
                    rows={1}
                    placeholder=""
                    defaultChecked={props?.dataModelPdxById?.ps100_status || ""}
                    onChange={(e) => setPs100(e.target.checked)}
                  />
                </Col>
                {/* 3e colonne de gènes */}

                <Col xs={12} md={6}>
                  <br />
                  <Row>
                    <Form.Label column="md" lg={2}>
                      TP53
                    </Form.Label>
                    <Col xs={12} md={4}>
                      <Form.Select
                        onChange={(e) => setTp53(e.target.value)}
                        autoFocus
                        size="sm"
                        id=""
                      >
                        <option>
                          {props?.dataModelPdxById?.tp53_status}
                        </option>
                        <option value="MUT">MUT</option>
                        <option value="WT">WT</option>
                      </Form.Select>
                    </Col>

                    <Col xs={12} md={4}>
                      {tp53 === "MUT" || props?.dataModelPdxById?.tp53_status === "MUT" ? (
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Type mutation"
                          defaultValue={
                            props?.dataModelPdxById?.tp53_mutation || ""
                          }
                          onChange={(e) => setTp53Mut(e.target.value)}
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label column="md" lg={2}>
                      ATM
                    </Form.Label>
                    <Col xs={12} md={4}>
                      <Form.Select
                        onChange={(e) => setAtm(e.target.value)}
                        autoFocus
                        size="sm"
                        id=""
                      >
                        <option>{props?.dataModelPdxById?.atm_status}</option>
                        <option value="MUT">MUT</option>
                        <option value="WT">WT</option>
                      </Form.Select>
                    </Col>

                    <Col xs={12} md={4}>
                      {atm === "MUT" || props?.dataModelPdxById?.atm_status === "MUT" ? (
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Type mutation"
                          defaultValue={
                            props?.dataModelPdxById?.atm_mutation || ""
                          }
                          onChange={(e) => setAtmMut(e.target.value)}
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label column="md" lg={2}>
                      KRAS
                    </Form.Label>
                    <Col xs={12} md={4}>
                      <Form.Select
                        onChange={(e) => setKras(e.target.value)}
                        autoFocus
                        size="sm"
                        id=""
                      >
                        <option>
                          {props?.dataModelPdxById?.kras_status}
                        </option>
                        <option value="MUT">MUT</option>
                        <option value="WT">WT</option>
                      </Form.Select>
                    </Col>

                    <Col xs={12} md={4}>
                      {kras === "MUT" || props?.dataModelPdxById?.kras_status === "MUT"? (
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Type mutation"
                          defaultValue={
                            props?.dataModelPdxById?.kras_mutation || ""
                          }
                          onChange={(e) => setKrasMut(e.target.value)}
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Form.Label column="md" lg={2}>
                      BRCA1
                    </Form.Label>
                    <Col xs={12} md={4}>
                      <Form.Select
                        onChange={(e) => setBrca1(e.target.value)}
                        autoFocus
                        size="sm"
                        id=""
                        defaultValue={props?.dataModelPdxById?.brca1_status || null}
                      >
                        <option value={""}></option>
                        <option value="MUT">MUT</option>
                        <option value="WT">WT</option>
                        <option value="ME">ME</option>
                        <option value="UME">UME</option>
                      </Form.Select>
                      {/* <Form.Select
                        onChange={(e) => setStatus(e.target.value)}
                        autoFocus
                        defaultValue={props?.dataPassageById?.status_greffe || null}
                      >
                        <option value={""}></option>
                        <option value={"F"}>Frais</option>
                        <option value={"D"}>Décongelé</option>
                      </Form.Select> */}
                    </Col>

                    <Col xs={12} md={4}>
                      {brca1 === "MUT" || props?.dataModelPdxById?.brca1_status === "MUT" ? (
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Type mut."
                          defaultValue={
                            props?.dataModelPdxById?.brca1_mutation || ""
                          }
                          onChange={(e) => setBrca1Mut(e.target.value)}
                        />
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col xs={12} md={10}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Commentaire</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder=""
                      defaultValue={
                        props?.dataModelPdxById?.commentaire_passage || ""
                      }
                      onChange={(e) => setCommentaire(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {props?.scope === "add" ? (
                <div>
                  <span className="textObligatoire">
                    Les champs avec astérisque (*) sont obligatoires.
                  </span>
                </div>
              ) : (
                ""
              )}
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="pe-2">
            <Button variant="success" onClick={handleEdit}>
              Enregistrer
            </Button>
          </div>
          <div className="ps-2">
            <Button variant="danger" onClick={handleClose}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalModelPdx;
