import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Col, Container, Form } from "react-bootstrap";
import _getAllRef from "../../../utils/getRef";
import { Typeahead } from "react-bootstrap-typeahead";
import { ResponsiveContainer } from "recharts";

const MesureGraph = () => {
  const [useRefresh, setUseRefresh] = useState();
  const [rawData, setRawData] = useState([]);

  let dataByPassage = {};

  let data = {};
  const [id_modele_pdx, setIdModelePdx] = useState();
  const [refModelePdx, setRefModelePdx] = useState();
  const [textTitle, setTextTitle] = useState();

  // Array of colors
  const colors = [
    "#FF6633",
    "#FFB399",
    "#FF33FF",
    "#FFFF99",
    "#00B3E6",
    "#E6B333",
    "#3366E6",
    "#999966",
    "#99FF99",
    "#B34D4D",
    "#80B300",
    "#809900",
    "#E6B3B3",
    "#6680B3",
    "#66991A",
    "#FF99E6",
    "#CCFF1A",
    "#FF1A66",
    "#E6331A",
    "#33FFCC",
    "#66994D",
    "#B366CC",
    "#4D8000",
    "#B33300",
    "#CC80CC",
    "#66664D",
    "#991AFF",
    "#E666FF",
    "#4DB3FF",
    "#1AB399",
    "#E666B3",
    "#33991A",
    "#CC9999",
    "#B3B31A",
    "#00E680",
    "#4D8066",
    "#809980",
    "#E6FF80",
    "#1AFF33",
    "#999933",
    "#FF3380",
    "#CCCC00",
    "#66E64D",
    "#4D80CC",
    "#9900B3",
    "#E64D66",
    "#4DB380",
    "#FF4D4D",
    "#99E6E6",
    "#6666FF",
  ];

  useEffect(() => {
    _getVtumFromOne();
    getRef();
    // console.log("REF",refModelePdx[19])
  }, [useRefresh, id_modele_pdx, textTitle]);

  const getRef = async () => {
    // Get all ref passage
    _getAllRef("modele_pdx")
      .then((res) => {
        res.unshift({
          id: null,
          ref_modele_pdx_icm: "",
        });
        setRefModelePdx(res);
        // setIdModelePdx(refModelePdx[0])
      })
      .catch((err) => {
        console.log("Error getRef modele pdx", err);
      });
  };

  const _getVtumFromOne = async () => {
    console.log("id_modele_pdx", id_modele_pdx);
    return axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/pdx/mesure/listForOne/${id_modele_pdx}`,
      withCredentials: true,
    })
      .then((res) => {
        setRawData(res.data);
      })
      .catch((err) => {
        return err;
      });
  };

  rawData.sort((a, b) => {
    return new Date(a.date_mesure) - new Date(b.date_mesure);
  });

  rawData.forEach((d) => {
    if (!dataByPassage[d.id_passage]) {
      dataByPassage[d.id_passage] = [];
    }
    dataByPassage[d.id_passage].push({
      ...d,
      date_mesure: d.date_mesure,
      vTum: ((d.diametre_a * d.diametre_a * d.diametre_b) / 2).toFixed(2),
    });
  });
  console.log("dataByPassage", dataByPassage);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "left",
      },
      title: {
        display: true,
        text: textTitle,
      },
    },
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  if (!dataByPassage) {
    return null;
  }
  const passages = Object.keys(dataByPassage);

  for (const p of passages) {
    //Find the first date in array
    let firstDate = new Date(dataByPassage[p][0].date_mesure);
    dataByPassage[p].map((v) => {
      return (v.diff = Math.round(
        (new Date(v.date_mesure) - firstDate) / (1000 * 60 * 60 * 24)
      ));
    });
  }

  data = {
    // labels,
    datasets: [],
  };

  // Function to generate unique colors
  function generateColors(numberOfColors) {
    const usedColors = new Set();
    const generatedColors = [];
    while (generatedColors.length < numberOfColors) {
      const colorIndex = Math.floor(Math.random() * colors.length);
      if (!usedColors.has(colorIndex)) {
        generatedColors.push(colors[colorIndex]);
        usedColors.add(colorIndex);
      }
    }
    return generatedColors;
  }
  generateColors(3);

  let allDates = [];
  const uniqueColors = generateColors(passages.length);

  for (const [i, passage] of passages.entries()) {
    data.datasets.push({
      label: dataByPassage[passage]?.[0]?.Passage?.num_hote,
      data: dataByPassage[passage].map((passage) => ({
        x: passage?.diff,
        y: passage?.vTum,
      })),
      borderColor: uniqueColors[i],
      backgroundColor: uniqueColors[i],
    });
    allDates.push(...dataByPassage[passage].map((e) => e.diff));
  }

  // Remove and sort duplicate dates
  allDates = [...new Set(allDates)].sort((a, b) => a - b);
  data.labels = allDates;

  return (
    <>
      <Container className="pb-5">
        {/* <Form.Control id="formGraph" size="sm" type="text" placeholder="" /> */}
        <Col md={1} xs={3} sm={5}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>MODELE PDX</Form.Label>

            <Typeahead
              style={{ margin: "0 px" }}
              id="basic-example"
              maxResults={10}
              defaultInputValue="b4122"
              emptyLabel="No Modele Pdx"
              // selected={id_modele_pdx}
              onChange={(e) => {
                console.log("ID", e[0]?.id);
                setIdModelePdx(e[0]?.id);
                setTextTitle(
                  "Evolution du modèle " +
                    e[0]?.ref_modele_pdx_icm +
                    " dans le temps"
                );
              }}
              options={refModelePdx}
              placeholder=""
              labelKey="ref_modele_pdx_icm"
            />
          </Form.Group>
        </Col>
        <ResponsiveContainer width="70%" height="70%" className={"col-3"}>
          <Line options={options} data={data} />
        </ResponsiveContainer>
      </Container>
    </>
  );
};

export default MesureGraph;
