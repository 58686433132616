import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { MutatingDots } from "react-loader-spinner";

const Loading = (props) => {
  return (
    <div>
      <div className="d-flex justify-content-center mt-5 pt-5">
        <MutatingDots color="var(--pink)" height={80} width={80} />
      </div>
      <h2 className="text-center mt-4">
        {props.titleSpinner}
      </h2>
    </div>
  );
};

export default Loading;
