import React from 'react';
import { Navigate, Route, Routes } from "react-router-dom";

import Home from "../view/Home";

import Aside from "../components/Aside/Aside";
import Footer from "../components/Footer/Footer";

// Views
import Patient from '../view/Patient/Patient';
import PdxModelList from '../view/PdxModel/PdxModelList';
import Profil from "../view/Profil";
import Passage from '../view/Passage/Passage';
import Tube from '../view/Tube/Tube';
import Dmso from '../view/Dmso/Dmso';
import Dry from '../view/Dry/Dry';
import Oct from '../view/Oct/Oct';
import Pfa from '../view/Pfa/Pfa';
import Mesure from '../view/Mesure/Mesure';
import Chirurgie from '../view/Chirurgie/Chirurgie';


import UserGestion from '../view/Dashboard/UserGestion';
import RoleGestion from '../view/Dashboard/RoleGestion';
import Log from '../view/Dashboard/Log';
import GraphMesure from '../view/GraphMesure/GraphMesure';



const index = () => {
  return (
    <div>
      <Aside />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/profil" element={<Profil />} />
        <Route path="/patient" element={<Patient />} />
        <Route path="/chirurgie" element={<Chirurgie />} />
        <Route path="/pdx_list" element={<PdxModelList />} />
        <Route path="/passage" element={<Passage />} />
        <Route path="/tubes" element={<Tube />} />
        <Route path="/dmso" element={<Dmso />} />
        <Route path="/dry" element={<Dry />} />
        <Route path="/oct" element={<Oct />} />
        <Route path="/pfa" element={<Pfa />} />
        <Route path="/prelevement" element={<Chirurgie />} />
        <Route path="/mesures" element={<Mesure />} />
        <Route path="/graph" element={<GraphMesure />} />
        <Route path="/user" element={<UserGestion />} />
        <Route path="/role" element={<RoleGestion />} />
        <Route path="/log" element={<Log />} />

        
        
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default index;
