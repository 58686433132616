import React, { useState } from "react";
import { Button} from "react-bootstrap";
import ModalPfa from "../Modal/ModalPfa";



const BtnAddPfa = (props) => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div>
      <Button
        variant="success"
        onClick={() => {
          setModalShow(true);
        }}
      >
        Ajouter un PFA 
      </Button>

      <ModalPfa
              setModalShow={setModalShow}
              modalShow={modalShow}
              setUseRefresh = {props.setUseRefresh}
              scope = {"add"}
              showToasts={props.showToasts}
              toggleShowToasts={props.toggleShowToasts}
              setContentToasts={props.setContentToasts}
              setStylesToasts={props.setStylesToasts}
            />
    </div>
  );
};

export default BtnAddPfa;
