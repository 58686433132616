import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./DmsoTable.css";

import { Button, Col, Container, Row } from "react-bootstrap";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";

import ModalDmso from "../../Modal/ModalDmso";
import BtnAddDmso from "../../Button/BtnAddDmso";

import Toasts from "../../Toasts/Toasts";
import ModalDelete from "../../Modal/ModalDelete";
import _dataTableFilter from "../../../utils/dataTableFilter";
import _exportData from "../../../utils/exportData";

const DmsoTable = () => {
  // Get user role to manage his rights and access
  let role = "";
  const auth = useContext(AuthContext);
  if (auth) {
    role = auth?.role?.dmso;
  }

  const [dataTube, setDataTube] = useState([]);
  const [dataTubeById, setDataTubeById] = useState("");
  const [tubeId, setTubeId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [hideAction, setHideAction] = useState(false);

  const [filter, setFilter] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        backgroundColor: "grey",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const fetchDmso = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/pdx/dmso/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataTube(res.data);
        })
        .catch((err) => {
          console.log("No data passage", err);
        });
    };
    fetchDmso();
  }, [useRefresh]);

  // Appel vers la fonction de recherche
  useEffect(() => {
    if (filter) {
      setDataFiltered(_dataTableFilter(dataTube, filter));
    }
    console.log("dataFiltered", dataFiltered);
  }, [filter]);

  // Catch data from selected passage
  const selectedDmso = (id) => {
    setDataTubeById(dataTube.find((e) => e.id === id));
  };

  const handleExport = () => {
    _exportData(dataFiltered || dataTube);
  };

  console.log(dataTube);

  // Construction des colonnes de la datatable
  const columns = [
    {
      selector: (row) => (
        <div>
          {/* Hide action column if user have not the autorisation */}
          {role?.page?.w === false && role?.page?.d === false
            ? setHideAction(true)
            : setHideAction(false)}
          <Row>
            {role?.page?.w === true ? (
              <Col
                variant="light"
                className="btnTable pe-3"
                onClick={(e) => {
                  e.preventDefault();
                  selectedDmso(row.id);
                  setModalShow(true);
                }}
              >
                <PencilSquare />
              </Col>
            ) : (
              ""
            )}

            {/* Delete */}
            {role?.page?.d === true ? (
              <Col
                variant="success"
                className="btnTable pe-3"
                onClick={(e) => {
                  e.preventDefault();
                  setTubeId(row.id);
                  setShowModalDelete(true);
                }}
              >
                <TrashFill />
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      ),
      name: "Action",
      button: true,
      width: "100px",
      center: true,
      omit: hideAction,
    },
    {
      name: <div>Emplacement</div>,
      selector: (row) => row.emplacement_tube,
      center: true,
      sortable: true,
      width: "120px",
    },
    {
      name: "Boite",
      selector: (row) => row.box,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Pos. boîte</div>,
      selector: (row) => row.box_position,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>REF PDX</div>,
      selector: (row) => row?.Passage?.Modele_pdx?.ref_modele_pdx_icm,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Passage</div>,
      selector: (row) => row?.Passage?.niveau_passage,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Date congélation</div>,
      selector: (row) => row.date_congelation?.split("T")[0],
      center: true,
      sortable: true,
      width: "110px",
    },
    {
      name: "ID Hote",
      selector: (row) => row?.Passage?.num_hote,
      center: true,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Aspect Tumeur",
      selector: (row) => row.req2,
      center: true,
      sortable: true,
      width: "220px",
    },
    {
      name: <div>Nécropsie</div>,
      selector: (row) => row.req1,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Manipu-lateur</div>,
      selector: (row) => row.manipulateur,
      center: true,
      sortable: true,
      width: "100px",
    },

    {
      name: <div>Echantillon valide</div>,
      selector: (row) => {
        switch (row.ech_valide) {
          case "0":
            return "";
          case "1":
            return "Oui";
          case "2":
            return "Non";
          default:
            return "";
        }
      },
      center: true,
      sortable: true,
      width: "110px",
    },
    {
      name: "Sortie",
      selector: (row) => {
        switch (row.sortie) {
          case "oui":
            return "Oui";
          case "1":
            return "Oui";
          case "non":
            return "Non";
          default:
            return "Non";
        }
      },
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>Date sortie</div>,
      selector: (row) => row.date_sortie?.split("T")[0],
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: "Envoi",
      selector: (row) => row.sortie_ext_tube,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: "Autre site",
      selector: (row) => row.site_sortie_tube,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Destinataire</div>,
      selector: (row) => row.dest_sortie_tube,
      center: true,
      sortable: true,
      width: "110px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      center: true,
      sortable: true,
      width: "100px",
    },
  ];

  return (
    <>
      <Container fluid>
        {auth ? (
          <div>
            {/* Toast */}
            <div className="toastsPosition">
              <Toasts
                showToasts={showToasts}
                toggleshowToasts={toggleShowToasts}
                contentToasts={contentToasts}
                stylesToasts={stylesToasts}
              />
            </div>
            <div className="container">
              <div className="row">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className="title_page">LISTE DES DMSO</h2>
                </div>
              </div>
            </div>
            <Row>
              <Col md={2}>
                {/* Bouton d'ajout */}
                <BtnAddDmso
                  setUseRefresh={setUseRefresh}
                  showToasts={showToasts}
                  toggleShowToasts={toggleShowToasts}
                  setContentToasts={setContentToasts}
                  setStylesToasts={setStylesToasts}
                />
              </Col>
              {/* Champ de recherche */}
              <Col>
                <input
                  type="text"
                  placeholder="Rechercher..."
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              {/* Bouton export */}
              <Col>
                <Button
                  variant="info"
                  onClick={() => {
                    handleExport();
                  }}
                >
                  Exporter
                </Button>
              </Col>
            </Row>
            {/* Tableau de données */}
            <DataTable
              pagination
              paginationRowsPerPageOptions={[25, 50, 100]}
              keyField="id"
              columns={columns}
              data={filter ? dataFiltered : dataTube}
              columnToggle
              dense={false}
              responsive={true}
              striped
              customStyles={customStyles}
              //Couleur des lignes
              rowStyle={{ backgroundColor: "red" }}
              fixedHeader={true}
            />
            {/* Appel du modal d'ajout et édition */}
            <ModalDmso
              dataTubeById={dataTubeById}
              setModalShow={setModalShow}
              modalShow={modalShow}
              setUseRefresh={setUseRefresh}
              scope={"edit"}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            />
            {/* Appel du modal de suppression */}
            <ModalDelete
              scope="deleteTube"
              id={tubeId}
              setUseRefresh={setUseRefresh}
              showModalDelete={showModalDelete}
              setShowModalDelete={setShowModalDelete}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            />
          </div>
        ) : (
          <div>
            <Loading titleSpinner="Loading data" />
          </div>
        )}
      </Container>
    </>
  );
};

export default DmsoTable;
