import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Toasts from "../Toasts/Toasts";

import _getAllRef from "../../utils/getRef";

import "./ModalIndex.css";

const ModalPassage = (props) => {
  const [status, setStatus] = useState();
  const [donneur, setDonneur] = useState();
  const [hote, setHote] = useState();
  const [etiquette, setEtiquette] = useState();
  const [nivPassage, setNivPassage] = useState();
  const [dateGreffe, setDateGreffe] = useState();
  const [dateDeces, setDateDeces] = useState();
  const [causeDeces, setCauseDeces] = useState();
  // eslint-disable-next-line
  const [dureePousse, setDureePousse] = useState();
  const [dA, setdA] = useState();
  const [dB, setdB] = useState();
  const [caracTumeur, setCaracTumeur] = useState();
  const [caracHote, setCaracHote] = useState();
  const [dateArrivee, setDateArrivee] = useState();
  const [commentaire, setCommentaire] = useState();
  const [modelePdx, setModelePdx] = useState([]);
  const [protocole, setProtocole] = useState();

  const [refModel, setRefModel] = useState([]);

  const handleClose = () => props.setModalShow(false);

  //Format Dates

  if (props?.dataPassageById?.date_greffe) {
    props.dataPassageById.date_greffe =
      props.dataPassageById.date_greffe.split("T")[0];
  }

  if (props?.dataPassageById?.date_deces) {
    props.dataPassageById.date_deces =
      props.dataPassageById.date_deces.split("T")[0];
  }

  if (props?.dataPassageById?.date_arrivee) {
    props.dataPassageById.date_arrivee =
      props.dataPassageById.date_arrivee.split("T")[0];
  }

  //Format Dates
  const current = new Date();
  const date = `${current.getFullYear()}/${
    current.getMonth() + 1
  }/${current.getDate()}`;

  useEffect(() => {
    getRef();
  }, []);

  // AXIOS
  const handleEditPassage = async (e) => {
    let url = "";
    let method = "";
    const data = {
      status_greffe: status,
      num_hote: hote,
      num_etiquette: etiquette,
      niveau_passage: nivPassage,
      num_donneur: donneur,
      date_greffe: dateGreffe,
      date_deces: dateDeces,
      cause_deces: causeDeces,
      duree_pousse: dureePousse,
      diametre_a: dA,
      diametre_b: dB,
      caract_tumeur: caracTumeur,
      caract_hote: caracHote,
      date_arrivee: dateArrivee,
      protocole: protocole,
      commentaire: commentaire,
      id_modele_pdx:
        modelePdx[0]?.id || props.dataPassageById?.Modele_pdx?.id || null,
    };
    let contentToasts = "";

    switch (props.scope) {
      case "edit":
        url = `${process.env.REACT_APP_API_URL}api/pdx/passage/update/${props.dataPassageById.id}`;
        method = "put";
        contentToasts = "Passage mis à jour";
        props.setStylesToasts("info");
        break;

      case "add":
        url = `${process.env.REACT_APP_API_URL}api/pdx/passage/add`;
        method = "post";
        contentToasts = "Passage ajouté";
        props.setStylesToasts("success");

        break;

      default:
        contentToasts = "ERREUR";
        props.setStylesToasts("danger");
        break;
    }
    e.preventDefault();

    await axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
    })
      .then(() => {
        props.setContentToasts(contentToasts);
        props.toggleShowToasts();
        props.setUseRefresh(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get reference passage
  const getRef = async () => {
    _getAllRef("modele_pdx")
      .then((res) => {
        setRefModel(res);
        console.log("refModel", refModel);
        console.log(props?.dataPassageById?.status_greffe);
      })
      .catch((err) => {
        console.log("Error getRef", err);
      });
  };

  return (
    <div>
      <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.scope === "edit"
              ? `Passage ${props.dataPassageById.num_donneur}`
              : "Ajout Passage"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Container>
            <Form>
              {/* Ligne 1 */}
              <Row>
                <Col xs={6} md={6}>
                  <Row>
                    <Col xs={6} md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Hote
                          {/* Asterisk for required input for add passage*/}
                          {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue={props?.dataPassageById?.num_hote || ""}
                          onChange={(e) => setHote(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Donneur</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue={
                            props?.dataPassageById?.num_donneur || ""
                          }
                          onChange={(e) => setDonneur(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Etiquette</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue={
                            props?.dataPassageById?.num_etiquette || ""
                          }
                          onChange={(e) => setEtiquette(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} md={6}>
                  <Row>
                    <Col xs={6} md={5}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Date Greffe</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          defaultValue={
                            props?.dataPassageById?.date_greffe || ""
                          }
                          onChange={(e) => setDateGreffe(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>
                          Passage
                          {/* Asterisk for required input for add passage*/}
                          {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Niv. Passage"
                          defaultValue={props?.dataPassageById?.niveau_passage}
                          onChange={(e) => setNivPassage(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={4}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Ref Id Pdx ICM</Form.Label>
                        {/* <Form.Control
                          type="text"
                          placeholder="XXX"
                          defaultValue={
                            props?.dataPassageById?.Modele_pdx
                              ?.ref_modele_pdx_icm
                          }
                          // row?.date_greffe?.split("T")[0]
                          onChange={(e) => setModelePdx(e.target.value)}
                          autoFocus
                        /> */}
                        <Typeahead
                          style={{ margin: "0 px" }}
                          id="basic-example"
                          defaultInputValue={
                            props?.dataPassageById?.Modele_pdx
                              ?.ref_modele_pdx_icm
                          }
                          maxResults={10}
                          // paginate={false}
                          emptyLabel="No Modele Pdx"
                          selected={modelePdx}
                          onChange={(e) => {
                            setModelePdx(e);
                          }}
                          options={refModel}
                          placeholder=""
                          labelKey="ref_modele_pdx_icm"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* Ligne 2 */}
              <Row>
                <Col xs={5} md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Date arrivée</Form.Label>
                    <Form.Control
                      type="date"
                      name="lastMesure"
                      defaultValue={props?.dataPassageById?.date_arrivee || ""}
                      onChange={(e) => setDateArrivee(e.target.value)}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={4}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Protocole</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder=""
                      defaultValue={props?.dataPassageById?.protocole || ""}
                      onChange={(e) => setProtocole(e.target.value)}
                      autoFocus
                    />
                  </Form.Group>
                </Col>
                <Col xs={6} md={3}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Etat</Form.Label>
                    <Form.Select
                      onChange={(e) => setStatus(e.target.value)}
                      autoFocus
                      defaultValue={
                        props?.dataPassageById?.status_greffe || null
                      }
                    >
                      <option value={""}></option>
                      <option value={"F"}>Frais</option>
                      <option value={"D"}>Décongelé</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>

              {/* Bloc visible uniquement à l'édition */}
              {props.scope === "edit" ? (
                <div>
                  <hr />
                  <Row>
                    <Col xs={4} md={2}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>dA (mm)</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          defaultValue={
                            props?.dataPassageById?.diametre_a || ""
                          }
                          onChange={(e) => setdA(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={4} md={2}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>dB (mm)</Form.Label>
                        <Form.Control
                          type="text"
                          disabled
                          defaultValue={
                            props?.dataPassageById?.diametre_b || ""
                          }
                          onChange={(e) => setdB(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={4} md={2}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Vtumoral</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          disabled={true}
                          defaultValue={
                            (props?.dataPassageById?.diametre_a *
                              props?.dataPassageById?.diametre_a *
                              props?.dataPassageById?.diametre_b) /
                              2 || ""
                          }
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={5} md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Dernière mesure</Form.Label>
                        <Form.Control
                          type="date"
                          defaultValue={
                            props?.dataPassageById?.date_greffe || ""
                          }
                          disabled
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Date sacrifice</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder=""
                          defaultValue={
                            props?.dataPassageById?.date_deces || ""
                          }
                          onChange={(e) => setDateDeces(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6} md={3}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Cause décès</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue={
                            props?.dataPassageById?.cause_deces || ""
                          }
                          onChange={(e) => setCauseDeces(e.target.value)}
                          autoFocus
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Caractéristique Tumeur </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          defaultValue={
                            props?.dataPassageById?.caract_tumeur || ""
                          }
                          onChange={(e) => setCaracHote(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>Caractéristique Hote </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          defaultValue={
                            props?.dataPassageById?.caract_hote || ""
                          }
                          onChange={(e) => setCaracHote(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              ) : (
                ""
              )}
              <hr />
              <Row>
                <Col xs={12} md={10}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Commentaire</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder=""
                      defaultValue={
                        props?.dataPassageById?.commentaire_passage || ""
                      }
                      onChange={(e) => setCommentaire(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {props?.scope === "add" ? (
                <div>
                  <span className="textObligatoire">
                    Les champs avec astérisque (*) sont obligatoires.
                  </span>
                </div>
              ) : (
                ""
              )}
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="pe-2">
            <Button variant="success" onClick={handleEditPassage}>
              Enregistrer
            </Button>
          </div>
          <div className="ps-2">
            <Button variant="danger" onClick={handleClose}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalPassage;
