import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";

import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

import "./ModalIndex.css";
import _getAllRef from "../../utils/getRef";
import ModalEditPwd from "./ModalEditPwd";

const ModalPfa = (props) => {
  // eslint-disable-next-line

  const [name, setName] = useState();
  const [lastname, setLastname] = useState();
  const [login, setLogin] = useState();
  const [team, setTeam] = useState();
  const [password, setPassword] = useState();
  const [mail, setMail] = useState();
  const [role, setRole] = useState([]);

  const [refRole, setRefRole] = useState();
  const [refUser, setRefUser] = useState();

  const [userId, setUserId] = useState();
  const [showModalEditPwd, setShowModalEditPwd] = useState();

  const handleClose = () => props.setModalShow(false);

  // Gestion des majuscules
  if (props.dataUserById.user_lastname) {
    props.dataUserById.user_lastname =
      props?.dataUserById?.user_lastname.replace(
        /^./,
        props?.dataUserById?.user_lastname[0].toUpperCase()
      );
  }
  if (props.dataUserById.user_name) {
    props.dataUserById.user_name = props?.dataUserById?.user_name.replace(
      /^./,
      props?.dataUserById?.user_name[0].toUpperCase()
    );
  }

  useEffect(() => {
    getRef();
  }, []);

  const getRef = async () => {
    // Get all ref passage
    _getAllRef("user/role")
      .then((res) => {
        console.log("res", res);
        res.unshift({
          id: null,
          nom_role: "",
        });

        setRefRole(res);
      })
      .catch((err) => {
        console.log("Error getRef role", err);
      });
  };

  //Format Dates
  const current = new Date();
  const date = `${current.getFullYear()}/${
    current.getMonth() + 1
  }/${current.getDate()}`;

  // AXIOS

  const handleEditUser = async (e) => {
    let url = "";
    let method = "";
    const data = {
      user_name: name,
      user_lastname: lastname,
      user_login: login,
      user_team: team,
      user_password: password,
      user_mail: mail,
      id_role: role[0]?.id,
    };
    let contentToasts = "";

    switch (props.scope) {
      case "edit":
        url = `${process.env.REACT_APP_API_URL}api/user/update/${props.dataUserById.id}`;
        method = "put";
        contentToasts = "Utilisateur mis à jour";

        props.setStylesToasts("info");
        break;

      case "add":
        url = `${process.env.REACT_APP_API_URL}api/user/register`;
        method = "post";
        contentToasts = "Utilisateur ajouté";
        props.setStylesToasts("success");

        break;

      default:
        contentToasts = "ERREUR";
        props.setStylesToasts("danger");
        break;
    }
    e.preventDefault();

    await axios({
      method: method,
      url: url,
      data: data,
      withCredentials: true,
    })
      .then(() => {
        props.setContentToasts(contentToasts);
        console.log("toats", contentToasts);
        props.toggleShowToasts();
        props.setUseRefresh(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Modal
        show={props.modalShow}
        onHide={() => props.setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.scope === "edit"
              ? `${props?.dataUserById?.user_name}` +
                " " +
                `${props?.dataUserById?.user_lastname.toUpperCase()}`
              : "Nouvel utilisateur "}
            {/*  */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <Container>
            <Form>
              <Col xs={12} md={12}>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId=""
                    >
                      <Form.Label>Nom
                        {/* Asterisk for required input for add passage*/}
                        {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={props?.dataUserById?.user_lastname || ""}
                        onChange={(e) => setLastname(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId=""
                    >
                      <Form.Label>Prénom
                        {/* Asterisk for required input for add passage*/}
                        {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={props?.dataUserById?.user_name || ""}
                        onChange={(e) => setName(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6} md={3}>
                    <Form.Group
                      className="mb-3"
                      controlId=""
                    >
                      <Form.Label>Equipe
                        {/* Asterisk for required input for add passage*/}
                        {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                      </Form.Label>
                      <Form.Select
                        onChange={(e) => setTeam(e.target.value)}
                        autoFocus
                      >
                        <option>{props?.dataUserById?.user_team}</option>
                        <option value="CT">CT</option>
                        <option value="CG">CG</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId=""
                    >
                      <Form.Label>Identifiant
                        {/* Asterisk for required input for add passage*/}
                        {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={props?.dataUserById?.user_login || ""}
                        onChange={(e) => setLogin(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId=""
                    >
                      <Form.Label>Mot de passe
                        {/* Asterisk for required input for add passage*/}
                        {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                      </Form.Label>
                      <Form.Control
                        type="password"
                        placeholder=""
                        defaultValue={props.scope === "add" ? "" : "********"}
                        disabled={props.scope === "add" ? false : true}
                        onChange={(e) => setPassword(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Col md={3}></Col>
                    <Col md={8}>
                      {props.scope === "edit" ? (
                        <div>
                          <Form.Label></Form.Label>
                          <Button
                            className="mt-4"
                            variant="primary"
                            onClick={(e) => {
                              e.preventDefault();
                              setUserId(props?.dataUserById?.id);
                              setShowModalEditPwd(true);
                            }}
                          >
                            Modifier le mot de passe
                          </Button>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Col>
                </Row>

                <Row>
                <Col>
                    <Form.Group
                      className="mb-3"
                      controlId=""
                    >
                      <Form.Label>Mail
                        {/* Asterisk for required input for add passage*/}
                        {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder=""
                        defaultValue={props?.dataUserById?.user_mail || ""}
                        onChange={(e) => setMail(e.target.value)}
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId=""
                    >
                      <Form.Label>Role
                        {/* Asterisk for required input for add passage*/}
                        {props?.scope === "add" ? (
                            <span className="chanObligatoire"> *</span>
                          ) : (
                            ""
                          )}
                      </Form.Label>

                      <Typeahead
                        style={{ margin: "0 px" }}
                        id="basic-example"
                        maxResults={10}
                        defaultInputValue={props?.dataUserById?.Role?.nom_role}
                        emptyLabel="Pas de rôle"
                        selected={role}
                        onChange={(e) => {
                          setRole(e);
                        }}
                        options={refRole}
                        placeholder=""
                        labelKey="nom_role"
                      />
                    </Form.Group>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </Col>

              {props?.scope === "add" ? (
                <div>
                  <span className="textObligatoire">
                    Les champs avec astérisque (*) sont obligatoires.
                  </span>
                </div>
              ) : (
                ""
              )}
            </Form>
          </Container>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="pe-2">
            <Button variant="success" onClick={handleEditUser}>
              Enregistrer
            </Button>
          </div>
          <div className="ps-2">
            <Button variant="danger" onClick={handleClose}>
              Fermer
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Modal for edit user password */}
      <ModalEditPwd
        showModalEditPwd={showModalEditPwd}
        setShowModalEditPwd={setShowModalEditPwd}
        userId={userId}
      />
      {/* scope="deleteModelePdx"
              id={modelPdxId}
              
              showModalDelete={showModalDelete}
              setShowModalDelete={setShowModalDelete}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts} */}
    </div>
  );
};

export default ModalPfa;
