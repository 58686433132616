import React from "react";

import { useContext } from "react";
import { AuthContext } from "../components/AppContext";
import Loading from "../components/Loading";
import Profil from "./Profil";

import logoIcm from "../assets/img/logo_icm.png";
import logoIrcm from "../assets/img/logo_ircm.png";

const Home = (props) => {
  const auth = useContext(AuthContext);
  console.log("auth",auth)
  return (
    <div>
      {/* Si user detecté */}
      {auth.uid !== null ? (
        <div>
          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
           
          </div>

          <div className="text-center">
            <h1>Bienvenue sur la base de données PDX</h1>
            <div className="mt-5">
              <img
                src={logoIcm}
                alt="Logo ICM"
                title="Accéder au site de l'ICM"
                className="logo"
              />
              <img
                src={logoIrcm}
                alt="Logo ICM"
                title="Accéder au site de l'IRCM"
                className="logo ms-5"
              />
            </div>
          </div>

          <div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      ) : (
        <div>
          {/* <Si pas d'user detecté : /> */}
          
            <Profil />
          
        </div>
      )}
    </div>
  );
};

export default Home;
