import axios from "axios";

let url = ""
const _getAllRef = async (flag) => {
    if(flag ==="user" || flag === "user/role"){
        url = `${process.env.REACT_APP_API_URL}api/`+flag+`/listref `
    } else {
        url = `${process.env.REACT_APP_API_URL}api/pdx/`+flag+`/listref `
    }
    return axios({
        method: 'get',
        url: url,
        withCredentials: true,
    })
    .then((res)=>{
        console.log("_getAllRef succes", res.data)
        return res.data;
    })
    .catch((err)=> {
        return err;
    });
}



export default _getAllRef;
