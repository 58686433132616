import React, { useContext } from "react";
import { AuthContext } from "../../components/AppContext";

import PassageTable from "../../components/Tables/PassageTable/PassageTable";
import Home from "../Home";
import Profil from "../Profil";

const Passage = (props) => {
  const auth = useContext(AuthContext);
  return (
    <>
      {/* Si user detecté */}
      {auth.uid !== null ? (
        auth?.role?.passage?.page.r === true ? (
          <div>
             
            <PassageTable />
            <br />
            <br />
          </div>
        ) : (
          <Home />
        )
      ) : (
        <div>
          {/* <Si pas d'user detecté : Redirect /profil /> */}
          <div>
            <Profil />
          </div>
        </div>
      )}
    </>
  );
};

export default Passage;
