// LeftNavbar construction

import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";

import axios from "axios";
import cookie from "js-cookie";

// CSS
import "./Aside.css";
// Bootsrap icons
import { BsBoxArrowRight, BsHouseFill } from "react-icons/bs";

import { AuthContext } from "../AppContext";
import _getRole from "../../utils/getRole";

const Aside = () => {
  const auth = useContext(AuthContext);
  const role = auth.role;
  const [userConnected, setUserConnected] = useState();

  // Get info for user connected

  useEffect(() => {
    const getMe = async () => {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}api/user/me`,
        data: { id: auth.uid },
        withCredentials: true,
      })
        .then((res) => {
          // res.data.user_name.replace(/^./,res.data.user_name[0].toUpperCase())
          setUserConnected(
            res.data.user_name.replace(
              /^./,
              res.data.user_name[0].toUpperCase()
            ) +
              " " +
              res.data.user_lastname.toUpperCase()
          );
        })
        .catch((err) => {
          console.log("No data user", err);
        });
    };

    // console.log(role);
    if (auth.uid != null) {
      getMe();
    } else {
      setUserConnected("");
    }
  }, [auth]);

  // console.log("Aside", role);
  const removeCookie = (key) => {
    if (window !== "undefined") {
      cookie.remove(key, { maxAge: 1 });
      console.log("cookie mangé");
    }
  };

  const logout = async () => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}api/user/logout`,
      withCredentials: true,
    })
      .then(() => {
        removeCookie("jwt");
      })
      .catch((err) => console.log(err));
    window.location = "/profil";
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div>
        <>
          <Navbar bg="light" variant="light ">
            <Container fluid className="container-nav col-12">
              <div className="title">
                <div className="d-flex">
                  <Button variant="outline-secondary" onClick={handleShow}>
                    <BsHouseFill />
                  </Button>
                  <Col md={3}>
                    
                  </Col>
                  <Col sm={5} md={12} className="mt-3">
                    <span className="">
                      <b>P</b>atient <b>D</b>erived <b>X</b>enografts
                    </span>
                  </Col>

                  <Nav.Link href="/home" target=""></Nav.Link>
                  <Nav.Link href="https://icm.unicancer.fr/" target="_blank">
                    <div>
                      {/* <div className="circle circle1"></div>
                      <div className="circle circle2"></div> */}
                    </div>
                  </Nav.Link>
                  {/* <Col sm={3} md={5} lg={1}></Col> */}
                  {/* <Col sm={3} md={5} lg={6}></Col> */}
                  {auth.uid ? (
                    <>
                      <Col sm={3} md={5}></Col>
                      <Col sm={3} md={2}></Col>
                      <Col md={4}>
                        <div>{userConnected}</div>
                        <Button variant="danger" onClick={() => logout()}>
                          Déconnexion
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {auth.uid ? (
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  id={`offcanvasNavbar-expand-false`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-false`}
                  placement="start"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                      <Nav.Link href="/">ACCUEIL</Nav.Link>
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-start flex-grow-1 pe-3">
                      <Dropdown.Divider className="" />

                      {role?.prelevement?.page.r === true ? (
                        <Nav.Link href="/patient">Patient</Nav.Link>
                      ) : (
                        ""
                      )}
                      <Dropdown.Divider className="" />

                      {role?.prelevement?.page.r === true ? (
                        <Nav.Link href="/chirurgie">Chirurgie</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.modele_pdx?.page.r === true ? (
                        <Nav.Link href="/pdx_list">Modèles PDX</Nav.Link>
                      ) : (
                        ""
                      )}

                      <Dropdown.Divider className="" />
                      {role?.passage?.page.r === true ? (
                        <Nav.Link href="/passage">Passages</Nav.Link>
                      ) : (
                        ""
                      )}

                      <Dropdown.Divider className="" />
                      {role?.tube?.page?.r === true ? (
                        <Nav.Link href="/tubes">Tubes</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.pfa?.page.r === true ? (
                        <Nav.Link href="/pfa">PFA</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.dmso?.page.r === true ? (
                        <Nav.Link href="/dmso">DMSO</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.dry?.page.r === true ? (
                        <Nav.Link href="/dry">DRY</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.oct?.page.r === true ? (
                        <Nav.Link href="/oct">OCT</Nav.Link>
                      ) : (
                        ""
                      )}

                      <Dropdown.Divider className="" />

                      {/* Rajouter conditions si les 3 pages ne sont pas en lecture */}

                      {role?.mesure?.page.r === true ? (
                        <Nav.Link href="/mesures">Gestion des mesures</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.mesure?.page.r === true ? (
                        <Nav.Link href="/graph">Graphiques</Nav.Link>
                      ) : (
                        ""
                      )}

                      <Dropdown.Divider className="" />

                      {/* <NavDropdown
                        title="Experimentations"
                        id={`offcanvasNavbarDropdown-expand-false`}
                      >
                        <NavDropdown.Item href="#action3">
                          Liste
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action3">
                          Graphs
                        </NavDropdown.Item>
                      </NavDropdown> */}
                      <br />
                      <br />

                      {role?.global?.admin === true ? (
                        <Nav.Link href="/role">Gestion des roles</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.global?.admin === true ? (
                        <Nav.Link href="/user">Utilisateurs</Nav.Link>
                      ) : (
                        ""
                      )}
                      {role?.global?.admin === true ? (
                        <Nav.Link href="/log">Logs</Nav.Link>
                      ) : (
                        ""
                      )}

                      {/* <Nav.Link href="/manage_users">
                        Gestion des utilisateurs
                      </Nav.Link> */}
                    </Nav>
                    <div className="mb-2">
                      <DropdownButton
                        key="up"
                        id={`dropdown-button-drop-up`}
                        drop="up"
                        variant="light"
                        title={userConnected}
                        className="btnDropMenu"
                      >
                        <Dropdown className="text-white">
                          <Dropdown.Item eventKey="1" onClick={() => logout()}>
                            Déconnexion
                          </Dropdown.Item>
                        </Dropdown>
                      </DropdownButton>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              ) : (
                <div></div>
              )}
            </Container>
          </Navbar>
        </>
      </div>
    </div>
  );
};

export default Aside;
