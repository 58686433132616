import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./PatientTable.css";

import { Col, Container, Form, Row } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";

import ModalDmso from "../../Modal/ModalDmso";
import BtnAddDmso from "../../Button/BtnAddDmso";

import Toasts from "../../Toasts/Toasts";
import { BsCheckCircleFill } from "react-icons/bs";
import _dataTableFilter from "../../../utils/dataTableFilter";

const PatientTable = () => {
  const uid = useContext(AuthContext);

  const [DataPatient, setDataPatient] = useState([]);
  const [DataPatientById, setDataPatientById] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [filter, setFilter] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);

  const [hideAction, setHideAction] = useState(false);
  const [hideCaracH, setHideCaracH] = useState(false);

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        backgroundColor: "grey",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const fetchChirurgie = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/pdx/patient/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataPatient(res.data);
        })
        .catch((err) => {
          console.log("No data patient", err);
        });
    };
    fetchChirurgie();
    setDataPatient(data);
  }, [useRefresh]);

  // Appel vers la fonction de recherche
  useEffect(() => {
    if (filter) {
      setDataFiltered(_dataTableFilter(DataPatient, filter));
    }
    console.log("dataFiltered", dataFiltered);
  }, [filter, dataFiltered]);

  // Catch data from selected passage
  const selectedDmso = (id) => {
    setDataPatientById(setDataPatient.find((e) => e.id === id));
  };

  // console.log(setDataPatient);
  const data = [
    {
      box: "",
      box_position: "",
      commentaire: null,
      createdAt: "",
      date_congelation: "",
      date_sortie: null,
      date_traitement: null,
      dest_sortie_tube: null,
      ech_valide: "",
      emplacement_tube: "",
      id_passage: 67,
      id_user: 1,
      manipulateur: null,
      req1: "",
      req2: "",
      req3: null,
      site_sortie_tube: "",
      sortie: "",
      sortie_ext_tube: null,
      status_tube: "",
      type: "",
    },
  ];

  // Construction des colonnes de la datatable
  const columns = [
    // {
    //   selector: (row) => (
    //     <div>
    //       <Row>
    //         {/* Delete */}
    //         {/* if() */}
    //         <Col
    //           variant="light"
    //           className="btnTable pe-3"
    //           onClick={(e) => {
    //             e.preventDefault();
    //             selectedDmso(row.id);
    //             setModalShow(true);
    //           }}
    //         >
    //           <PencilSquare />
    //         </Col>
    //       </Row>
    //     </div>
    //   ),
    //   name: "Action",
    //   button: true,
    //   width: "100px",
    //   center: true,
    //   omit: hideAction,
    // },

    {
      name: <div>Origine</div>,
      selector: (row) => row.origine,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>ID ICM</div>,
      selector: (row) => row.id_icm,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Sexe</div>,
      selector: (row) => row?.sexe,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Date de naissance</div>,
      selector: (row) => row?.date_naissance?.split("T")[0],
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Age à la chirurgie</div>,
      selector: (row) => row.age_patient_chirurgie,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Date état</div>,
      selector: (row) => row?.date_etat?.split("T")[0],
      center: true,
      sortable: true,
      width: "100px",
      wrap: true,
      omit: hideCaracH,
    },
    {
      name: <div>Récidive</div>,
      selector: (row) => {
        return <div>{row.recidive === true ? <BsCheckCircleFill /> : ""}</div>;
      },
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Date récidive</div>,
      selector: (row) => row?.date_recidive?.split("T")[0],
      center: true,
      sortable: true,
      width: "120px",
    },
    {
      name: <div>RFS</div>,
      selector: (row) => row.rfs,
      center: true,
      sortable: true,
      width: "110px",
    },
    {
      name: <div>Décès</div>,
      selector: (row) => {
        return <div>{row.deces === true ? <BsCheckCircleFill /> : ""}</div>;
      },
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Date décès</div>,
      selector: (row) => row?.date_deces?.split("T")[0],
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>OVS</div>,
      selector: (row) => row.ovs,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Prédispo génétique</div>,
      selector: (row) => row.predispo_gen,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Métastases au diagnostique</div>,
      selector: (row) => row.metastases_diag,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>Site métastases</div>,
      selector: (row) => row.site_meta,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>NAC</div>,
      selector: (row) => row.nac,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Type de NAC</div>,
      selector: (row) => row.type_nac,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Chimio Adjuvante</div>,
      selector: (row) => row.ct_adj,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>Type chimio adjuvante</div>,
      selector: (row) => row.type_ct_adj,
      center: true,
      sortable: true,
      width: "80px",
    },
  ];

  return (
    <>
      <Container fluid>
        {uid ? (
          <div>
            {/* Toast */}
            <div className="toastsPosition">
              <Toasts
                showToasts={showToasts}
                toggleshowToasts={toggleShowToasts}
                contentToasts={contentToasts}
                stylesToasts={stylesToasts}
              />
            </div>
            {/* Titre */}
            <div className="container">
              <div className="row">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className="title_page">LISTE PATIENTS</h2>
                </div>
              </div>
            </div>
            {/* Champ de recherche */}
            <Col>
              <input
                type="text"
                placeholder="Rechercher..."
                onChange={(e) => setFilter(e.target.value)}
              />
            </Col>
            {/* Tableau de données */}
            <DataTable
              pagination
              paginationRowsPerPageOptions={[25, 50, 100]}
              keyField="id"
              columns={columns}
              data={filter ? dataFiltered : DataPatient}
              columnToggle
              dense={false}
              responsive={true}
              striped
              customStyles={customStyles}
              //Couleur des lignes
              rowStyle={{ backgroundColor: "red" }}
              fixedHeader={true}
            />
            {/* Appel du modal d'ajout et édition */}
            {/* <ModalDmso
              dataTubeById={dataTubeById}
              setModalShow={setModalShow}
              modalShow={modalShow}
              setUseRefresh={setUseRefresh}
              scope={"edit"}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            /> */}
          </div>
        ) : (
          <div>
            <Loading titleSpinner="Loading data" />
          </div>
        )}
      </Container>
    </>
  );
};

export default PatientTable;
