import axios from "axios";
import React from "react";
import { Button, Modal } from "react-bootstrap";

const ModalDelete = (props) => {
  let url = "";
  let method = "";
  let contentToasts = "";

  const handleClose = () => props.setShowModalDelete(false);

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    console.log("props.scope", props.scope);
    console.log("props.id", props.id);
    switch (props.scope) {
      case "deletePassage":
        url = `${process.env.REACT_APP_API_URL}api/pdx/passage/delete/${props.id}`;
        method = "delete";
        contentToasts = "Passage supprimé";
        props.setStylesToasts("info");
        break;
      case "deleteModelePdx":
        url = `${process.env.REACT_APP_API_URL}api/pdx/modele_pdx/delete/${props.id}`;
        method = "delete";
        contentToasts = "Modèle PDX supprimé";
        props.setStylesToasts("info");
        break;
      case "deleteTube":
        url = `${process.env.REACT_APP_API_URL}api/pdx/tube/delete/${props.id}`;
        method = "delete";
        contentToasts = "Tube supprimé";
        props.setStylesToasts("info");
        break;
      case "deleteMesure":
        url = `${process.env.REACT_APP_API_URL}api/pdx/mesure/delete/${props.date}`;
        method = "delete";
        contentToasts = "Session de mesures supprimée";
        props.setStylesToasts("info");
        break;
      case "deleteRole":
        url = `${process.env.REACT_APP_API_URL}api/user/role/delete/${props.id}`;
        method = "delete";
        contentToasts = "Rôle supprimé";
        props.setStylesToasts("info");
        break;
      case "deleteUser":
        url = `${process.env.REACT_APP_API_URL}api/user/delete/${props.id}`;
        method = "delete";
        contentToasts = "Utilisateur supprimé";
        props.setStylesToasts("info");
        break;
      default:
        break;
    }
    axios({
      url: url,
      method: method,
      withCredentials: true,
    })
      .then((res) => {
        props.setContentToasts(contentToasts);
        props.toggleShowToasts();
        props.setUseRefresh(true);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
    props.setShowModalDelete(false);
  };

  return (
    <div>
      <Modal
        show={props.showModalDelete}
        onHide={() => props.setShowModalDelete(false)}
        size="lg"
        centered="true"
      >
        <Modal.Body className="contentDelete">
          {props.scope === "deleteMesure" ? (
            <span>
              Attention cette action supprimera toutes les mesures liées a cette
              session de messure ! <br />
              Voulez vous vraiment supprimer définitivement cet élément ? <br />
              <br />
              Attention cette action est irréversible
            </span>
          ) : (
            <span>
              Voulez vous vraiment supprimer définitivement cet élément ?
              Attention cette action est irréversible
            </span>
          )}
          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.setShowModalDelete(false)}
          >
            Non
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Oui
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalDelete;
