import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../components/AppContext";
import PatientTable from "../../components/Tables/PatientTable/PatientTable";


import Home from "../Home";
import Profil from "../Profil";

const Patient = (props) => {
  const auth = useContext(AuthContext);

  useEffect(() => {
    
  }, []);

  // console.log(role);
  return (
    <div>
      {/* Si user detecté */}
      {auth.uid !== null ? (
        // auth?.role?.tube?.page?.r === true ? (
          <div>
            <PatientTable />
            <br />
            <br />
          </div>
        // ) : (
          // <Home />
        // )
      ) : (
        <div>
          {/* <Si pas d'user detecté : Redirect /profil /> */}
          <div>
            <Profil />
          </div>
        </div>
      )}
    </div>
  );
};

export default Patient;
