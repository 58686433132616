import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./UserGestionTable.css";
import _getRole from "../../../utils/getRole";

import { Button, Col, Container, Form, Row } from "react-bootstrap";

import Toasts from "../../Toasts/Toasts";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";
import ModalDelete from "../../Modal/ModalDelete";
import ModalUserGestion from "../../Modal/ModalUserGestion";
import _dataTableFilter from "../../../utils/dataTableFilter";

const UserGestionTable = () => {
  const uid = useContext(AuthContext);

  const [dataUser, setDataUser] = useState([]);
  const [dataUserById, setDataUserById] = useState("");
  const [userId, setUserId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [scope, setScope] = useState();

  const [filter, setFilter] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);

  // Datatable variables :
  const widthcommment = "150px";

  const [hideAction, setHideAction] = useState(false);
  const [hideComment, setHideComment] = useState(false);
  const [hideCaracTum, setHideCaracTum] = useState(false);
  const [hideCaracH, setHideCaracH] = useState(false);

  const [omitTest, setOmitTest] = useState(false);

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        backgroundColor: "MediumVioletRed",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const fetchUser = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/user/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataUser(res.data);
        })
        .catch((err) => {
          console.log("No data user", err);
        });
    };
    fetchUser();
  }, [useRefresh]);

  // Appel vers la fonction de recherche
  useEffect(() => {
    if (filter) {
      setDataFiltered(_dataTableFilter(dataUser, filter));
    }
    console.log("dataFiltered", dataFiltered);
  }, [filter, dataFiltered]);

  // Catch data from selected user
  const selectedUser = (id) => {
    setDataUserById(dataUser.find((e) => e.id === id));
  };

  console.log(dataUser);

  // Construction des colonnes de la datatable
  const columns = [
    {
      selector: (row) => (
        <div>
          <Row>
            {/* if() */}
            <Col
              variant="light"
              className="btnTable pe-3"
              onClick={(e) => {
                e.preventDefault();
                selectedUser(row.id);
                setModalShow(true);
                setScope("edit");
              }}
            >
              <PencilSquare />
            </Col>
            {/* Delete */}
            <Col
              variant="success"
              className="btnTable pe-3"
              onClick={(e) => {
                e.preventDefault();
                setUserId(row.id);
                setShowModalDelete(true);
              }}
            >
              <TrashFill />
            </Col>
          </Row>
        </div>
      ),
      name: "Action",
      button: true,
      width: "100px",
      center: true,
      omit: hideAction,
    },
    {
      name: <div>Nom</div>,
      selector: (row) =>
        row.user_lastname.replace(/^./, row.user_lastname[0].toUpperCase()) ||
        "",
      center: true,
      sortable: true,
      width: "200px",
    },
    {
      name: <div>Prénom</div>,
      selector: (row) =>
        row.user_name.replace(/^./, row.user_name[0].toUpperCase()) || "",
      center: true,
      sortable: true,
      width: "150px",
      omit: hideCaracTum,
      wrap: true,
    },

    {
      name: <div>Identifiant</div>,
      selector: (row) => row.user_login,
      center: true,
      sortable: true,
      // width: "100px",
      wrap: true,
    },
    {
      name: <div>Equipe</div>,
      selector: (row) => row.user_team,
      center: true,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: <div>Accès</div>,
      selector: (row) => row?.Role?.nom_role,
      center: true,
      sortable: true,
      width: "150px",
      wrap: true,
    },
  ];

  return (
    <>
      <Row>
        <Col md={1}></Col>
        <Col md={10}>
          <Container>
            {uid ? (
              <div>
                {/* Toast */}
                <div className="toastsPosition">
                  <Toasts
                    showToasts={showToasts}
                    toggleshowToasts={toggleShowToasts}
                    contentToasts={contentToasts}
                    stylesToasts={stylesToasts}
                  />
                </div>
                <div className="container">
                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h2 className="title_page">LISTE DES UTILISATEURS</h2>
                    </div>
                  </div>
                </div>
                <Row>
                  <Col md={2}></Col>
                  <Col md={2}>
                    <Button
                      variant="success"
                      onClick={() => {
                        setModalShow(true);
                        setScope("add");
                      }}
                    >
                      Ajouter un utilisateur
                    </Button>
                  </Col>
                  {/* Champ de recherche */}
                  <Col>
                    <input
                      type="text"
                      placeholder="Rechercher..."
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={2}></Col>
                  <Col md={8}>
                    {/* Tableau de données */}
                    <DataTable
                      pagination
                      paginationRowsPerPageOptions={[25, 50, 100]}
                      keyField="id"
                      columns={columns}
                      data={filter ? dataFiltered : dataUser}
                      columnToggle
                      dense={false}
                      responsive={true}
                      striped
                      customStyles={customStyles}
                      //Couleur des lignes
                      rowStyle={{ backgroundColor: "red" }}
                      fixedHeader={true}
                    />
                  </Col>
                </Row>
                {/* Appel du modal de gestion des utilisateurs*/}
                <ModalUserGestion
                  dataUserById={dataUserById}
                  setModalShow={setModalShow}
                  modalShow={modalShow}
                  setUseRefresh={setUseRefresh}
                  scope={scope}
                  showToasts={showToasts}
                  toggleShowToasts={toggleShowToasts}
                  setContentToasts={setContentToasts}
                  setStylesToasts={setStylesToasts}
                />

                {/* Appel du modal de suppression */}
                <ModalDelete
                  scope="deleteUser"
                  id={userId}
                  setUseRefresh={setUseRefresh}
                  showModalDelete={showModalDelete}
                  setShowModalDelete={setShowModalDelete}
                  showToasts={showToasts}
                  toggleShowToasts={toggleShowToasts}
                  setContentToasts={setContentToasts}
                  setStylesToasts={setStylesToasts}
                />
              </div>
            ) : (
              <div>
                <Loading titleSpinner="Loading data" />
              </div>
            )}
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default UserGestionTable;
