module.exports.role_format = {
  modele_pdx: {
    page: {
      w: true,
      r: true,
      d: true,
    },
    ref_modele_pdx_icm: {
      lecture: true, 
      ecriture: true,
    },
    date_greffe_modele_pdx: {
      lecture: true,
      ecriture: true,
    },
    count_passage: {
      lecture: true,
      ecriture: true,
    },
  },
  tube: {
    page: {
      w: true,
      r: true,
      d: true,
    },
    emplacement_tube: {
      lecture: true,
      ecriture: true,
    },
    box: {
      lecture: true,
      ecriture: true,
    },
    box_position: {
      lecture: true,
      ecriture: true,
    },
  },
  passage: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  prelevement: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  pfa: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  dmso: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  dry: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  oct: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  mesure: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  experimentation: {
    page: {
      w: true,
      r: true,
      d: true,
    },
  },
  global: {
    admin: false,
    edit: false,
    delete: false,
  },
};


// module.exports.role_formattt = 3