import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./LogTable.css";

import { Col, Container, Row } from "react-bootstrap";



const LogTable = () => {
  // Get user role to manage his rights and access
  let role = "";
  const auth = useContext(AuthContext);
  if (auth) {
    role = auth?.role?.dmso;
  }

  const [logs, setLogs] = useState([]);


  const [useRefresh, setUseRefresh] = useState();


 

 

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        backgroundColor: "grey",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    row: {
      height:"5px",
      backgroundColor: "red"
    },
  };

  const conditionalRowStyles = [
    // {
    //   when: row => row.level === "info",
    //   style: {
    //     backgroundColor: 'rgba(35, 210, 82, 0.66)',
    //     color: 'white',
    //     '&:hover': {
    //       cursor: 'pointer',
    //     },
    //   },
    // },
    // You can also pass a callback to style for additional customization
    // {
    //   when: row => row.calories < 300,
    //   style: row => ({
    //     backgroundColor: row.isSpecia ? 'pink' : 'inerit',
    //   }),
    // },
  ];

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const fetchLog = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/user/logs`,
        withCredentials: true,
      })
        .then((res) => {
          setLogs(res.data);
        })
        .catch((err) => {
          console.log("No logs", err);
        });
    };
    fetchLog();
  }, [useRefresh]);

 

  // Construction des colonnes de la datatable
  const columns = [
    {
      name: <div>Données</div>,
      selector: (row) => row.component,
      center: true,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Level",
      selector: (row) => row.level,
      center: true,
      sortable: true,
      // width: "100px",
    },
    {
      name: <div>User</div>,
      selector: (row) => row.user,
      center: true,
      sortable: true,
      // width: "100px",
    },
    {
      name: <div>Date</div>,
      selector: (row) => row.timestamp,
      center: true,
      sortable: true,
    },
    {
      name: <div>Message</div>,
      selector: (row) => row?.message,
      center: true,
      sortable: true,
      // width: "90px",
    },
    
  ];

  return (
    <>
      <Container>
        {auth ? (
          <div>
            
            <div className="container">
              <div className="row">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className="title_page">LOGS</h2>
                </div>
              </div>
            </div>
            <DataTable
              pagination
              paginationRowsPerPageOptions={[25, 50, 100]}
              keyField="id"
              columns={columns}
              data={logs}
              columnToggle
              dense={false}
              responsive={true}
              striped
              customStyles={customStyles}
              //Couleur des lignes
              fixedHeader={true}
              conditionalRowStyles={conditionalRowStyles}
            />

            
            
          </div>
        ) : (
          <div>
            <Loading titleSpinner="Loading data" />
          </div>
        )}
      </Container>
    </>
  );
};

export default LogTable;
