import React, {  } from "react";
import SignInForm from "./SignInForm";

// CSS
import "./index.css";

const Login = (props) => {

  return (
    <SignInForm />
  );
};

export default Login;
