import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./RoleGestionTable.css";

import { Col, Container, Form, Row } from "react-bootstrap";

import Toasts from "../../Toasts/Toasts";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";
import BtnAddRole from "../../Button/BtnAddRole";

import ModalDelete from "../../Modal/ModalDelete";
import ModalRole from "../../Modal/ModalRole";

const RoleGestionTable = () => {
  const uid = useContext(AuthContext);

  const [dataRole, setDataRole] = useState([]);
  const [dataRoleById, setDataRoleById] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const [roleId, setRoleId] = useState("");
  const [showModalDelete, setShowModalDelete] = useState(false);

  // Datatable variables :

  const [omitTest, setOmitTest] = useState(false);

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        backgroundColor: "MediumVioletRed",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const test = "true";
    console.log("test", JSON.parse(test));
    const fetchRole = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/user/role/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataRole(res.data);
        })
        .catch((err) => {
          console.log("No data role", err);
        });
    };
    fetchRole();
  }, [useRefresh]);

  // Catch data from selected role
  const selectedRole = (id) => {
    // const tempRole = dataRole.find((e) => e.id === id)
    // excludeField = JSON.parse(tempRole.exclude_field)

    const tempData = dataRole.find((e) => e.id === id);
    console.log("tyty", tempData);

    let object = {};

    if (tempData?.exclude_field) {
      object = {
        ...tempData,
        excludeParse: JSON.parse(tempData.exclude_field),
      };
      console.log("object",object)
    }

    setDataRoleById(object);
  };

  // console.log("ID",dataRoleById);

  // Construction des colonnes de la datatable
  const columns = [
    {
      selector: (row) => (
        <div>
          <Row>
            {/* if() */}
            <Col
              variant="light"
              className="btnTable pe-3"
              onClick={(e) => {
                e.preventDefault();
                selectedRole(row.id);
                setModalShow(true);
              }}
            >
              <PencilSquare />
            </Col>
            {/* Delete */}
            <Col
              variant="success"
              className="btnTable pe-3"
              onClick={(e) => {
                e.preventDefault();
                setRoleId(row.id);
                setShowModalDelete(true);
              }}
            >
              <TrashFill />
            </Col>
          </Row>
        </div>
      ),
      name: "Action",
      button: true,
      width: "100px",
      center: true,
    },
    {
      name: "Nom du role",
      selector: (row) => row.nom_role,
      center: true,
      sortable: true,
      width: "200px",
    },
  ];

  return (
    <>
      <Row>
        <Col md={1}></Col>

        <Col md={10}>
          <Container>
            {uid ? (
              <div>
                {/* Toast */}
                <div className="toastsPosition">
                  <Toasts
                    showToasts={showToasts}
                    toggleshowToasts={toggleShowToasts}
                    contentToasts={contentToasts}
                    stylesToasts={stylesToasts}
                  />
                </div>
                <div className="container">
                  <div className="row">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <h2 className="title_page">LISTE DES RÔLES</h2>
                    </div>
                  </div>
                </div>
                <Col md={2}>
                  <BtnAddRole
                    setUseRefresh={setUseRefresh}
                    showToasts={showToasts}
                    toggleShowToasts={toggleShowToasts}
                    setContentToasts={setContentToasts}
                    setStylesToasts={setStylesToasts}
                  />
                </Col>
                <Col md={5}>
                  <DataTable
                    pagination
                    paginationRowsPerPageOptions={[25, 50, 100]}
                    keyField="id"
                    columns={columns}
                    data={dataRole}
                    columnToggle
                    dense={false}
                    responsive={true}
                    striped
                    customStyles={customStyles}
                    //Couleur des lignes
                    rowStyle={{ backgroundColor: "red" }}
                    fixedHeader={true}
                  />
                </Col>
                <ModalRole
                  dataRoleById={dataRoleById}
                  setModalShow={setModalShow}
                  modalShow={modalShow}
                  setUseRefresh={setUseRefresh}
                  scope={"edit"}
                  showToasts={showToasts}
                  toggleShowToasts={toggleShowToasts}
                  setContentToasts={setContentToasts}
                  setStylesToasts={setStylesToasts}
                />
                <ModalDelete
                  scope="deleteRole"
                  id={roleId}
                  setUseRefresh={setUseRefresh}
                  showModalDelete={showModalDelete}
                  setShowModalDelete={setShowModalDelete}
                  showToasts={showToasts}
                  toggleShowToasts={toggleShowToasts}
                  setContentToasts={setContentToasts}
                  setStylesToasts={setStylesToasts}
                />
              </div>
            ) : (
              <div>
                <Loading titleSpinner="Loading data" />
              </div>
            )}
          </Container>
        </Col>
      </Row>
    </>
  );
};

export default RoleGestionTable;
