// Gestion et analyse du role de l'utilisateur en front
// Vérification du role pour l'accès de la page
// Vérification du role pour l'accès aux colonnes
// Vérification du role pour l'accès à la modification et à la suppression
// Vérification des droits globaux (qui vont interdire ou non l'accès à d'autres accès par hierarchie)

import axios from "axios";

// On récup l'id de l'user
// On récup le role d' l'user
// On le json.parse
// Et on fait un switch case en fonction de la page demandée / l'appel demandé etc

// Faire un appel depuis aside pour masquer les pages inaccessibles

// Si  context.r == false
// si context. w == false
// si context.d == false

const _getRole = async (uid) => {
  // On recup le role
  console.log("uid get role", uid);
  if (!uid) {
    return "No uid";
  }
  const result = await axios({
    method: "get",
    url: `${process.env.REACT_APP_API_URL}api/user/list/${uid}`,
    withCredentials: true,
  });

  const temp = JSON.parse(result.data.Role.exclude_field);
  console.log(temp);
  return temp;
};

export default _getRole;
