const _dataTableFilter = (obj, searchValue) => {
  let results = [];
  let keyss = null;
  // On boucle sur chaque objet enfant
  const filterObj = (child1, searchValue, a) => {
    for (const key in child1) {
      const children = child1[key];
      if (child1.hasOwnProperty(key)) {
        // Si c'est un objet on boucle encore
        if (typeof children === "object") {
          filterObj(children, searchValue, a);
        } else if (children.toString().includes(searchValue)) {
          // Si la veleur est trouvée on renvoit la ligne trouvée
          keyss = a;
        }
      }
    }
  };

  // On boucle une premiere fois sur le tableau de données
  for (const a in obj) {
    const objet = obj[a];
    filterObj(objet, searchValue, a);
    // Si une ligne a été retournée, on push dans le tableau de résultats, et on remet à 0
    if (keyss !== null) {
      results.push(obj[keyss]);
      keyss = null;
    }
  }
  console.log("results", results);
  return results
};

export default _dataTableFilter;
