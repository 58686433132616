import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./MesureTable.css";

import { Col, Container, Row } from "react-bootstrap";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";

import ModalMesure from "../../Modal/ModalMesure";

import Toasts from "../../Toasts/Toasts";
import BtnAddMesure from "../../Button/BtnAddMesure";
import ModalDelete from "../../Modal/ModalDelete";

const MesureTable = () => {
  // Get user role to manage his rights and access
  let role = "";
  const auth = useContext(AuthContext);
  if (auth) {
    role = auth?.role?.mesure;
  }

  const [dataMesure, setDataMesure] = useState([]);
  const [dataMesureById, setDataMesureById] = useState("");

  const [singleDate, setSingleDate] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);


  // Datatable variables :
  const [hideAction, setHideAction] = useState(false);

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        backgroundColor: "grey",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  useEffect(() => {
     //  Hide action column if user have not the autorisation
     role?.page?.w === false && role?.page?.d === false
     ? setHideAction(true)
     : setHideAction(false);

    // On recupere les data en interrogeant le back end
    const fetchMesure = async () => {
      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/pdx/mesure/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataMesure(res.data);
          const unique = [
            ...new Map(
              res.data.map((item) => [item.date_mesure, item])
            ).values(),
          ];
          setSingleDate(unique);
        })
        .catch((err) => {
          console.log("No data mesure", err);
        });
    };
    // On récupère les dates uniques

    fetchMesure();
    // getSingleDate();
  }, [useRefresh]);

  // console.log("DataMesure", dataMesure);
  console.log("singleDate", singleDate);

  // Catch data from selected passage
  const selectedDateMesure = (id) => {
    const mesureId = singleDate.find((e) => e.id === id);
    setDataMesureById(
      dataMesure.filter((e) => e.date_mesure === mesureId.date_mesure)
    );
  };

  console.log(dataMesureById);

  // Construction des colonnes de la datatable
  const columns = [
    {
      name: "Mesures",
      // date.toLocaleDateString("fr-FR").substring(0, 10)
      selector: (row) => "Mesures du " + new Date(row.date_mesure).toLocaleDateString("fr-FR"),
      center: true,
      sortable: true,
      maxWidth: "",
    },
    // {
    //   name: "Mesures",
    //   selector: (row) => row.id,
    //   center: true,
    //   sortable: true,
    //   maxWidth: "",
    // },
    {
      selector: (row) => (
        <div>
          <Row>
            {role?.page?.w === true ? (
              <Col
                variant="light"
                className="btnTable pe-3"
                onClick={(e) => {
                  e.preventDefault();
                  selectedDateMesure(row.id);
                  setModalShow(true);
                }}
              >
                <PencilSquare />
              </Col>
            ) : (
              ""
            )}

            {/* Delete */}
            {role?.page?.d === true ? (
              <Col
                variant="success"
                className="btnTable pe-3"
                onClick={(e) => {
                  e.preventDefault();
                  setDataMesureById(row.id);
                  setShowModalDelete(true);
                }}
              >
                <TrashFill />
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      ),
      name: "",
      button: true,
      width: "100px",
      center: true,
      omit: hideAction,
    },
  ];

  return (
    <>
      <Container>
        {auth ? (
          <div>
            {/* Toast */}
            <div className="toastsPosition">
              <Toasts
                showToasts={showToasts}
                toggleshowToasts={toggleShowToasts}
                contentToasts={contentToasts}
                stylesToasts={stylesToasts}
              />
            </div>
            <div className="container">
              <div className="row">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className="title_page">LISTE DES MESURES</h2>
                </div>
              </div>
            </div>
            <Row>
              <Col md={5}>
                <DataTable
                  pagination
                  paginationRowsPerPageOptions={[25, 50, 100]}
                  keyField="id"
                  columns={columns}
                  data={singleDate}
                  columnToggle
                  dense={false}
                  responsive={true}
                  striped
                  customStyles={customStyles}
                  fixedHeader={true}
                />
              </Col>
              <Col md={1}></Col>
              <Col md={2}>
                <BtnAddMesure />
              </Col>
            </Row>

            <ModalMesure
              dataMesureById={dataMesureById}
              setModalShow={setModalShow}
              modalShow={modalShow}
              setUseRefresh={setUseRefresh}
              scope={"edit"}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            />
            <ModalDelete
              scope="deleteMesure"
              date={dataMesureById[0]?.date_mesure}
              setUseRefresh={setUseRefresh}
              showModalDelete={showModalDelete}
              setShowModalDelete={setShowModalDelete}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            />
          </div>
        ) : (
          <div>
            <Loading titleSpinner="Loading data" />
          </div>
        )}
      </Container>
    </>
  );
};

export default MesureTable;
