import { useEffect, useState } from "react";

import { useContext } from "react";
import { AuthContext } from "../../AppContext";

import DataTable, { defaultThemes } from "react-data-table-component";
import Loading from "../../Loading";

import axios from "axios";

// CSS
import "./PassageTable.css";

import { Button, Col, Container, Row } from "react-bootstrap";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";

import ModalPassage from "../../Modal/ModalPassage";
import BtnAddPassage from "../../Button/BtnAddPassage";
import Toasts from "../../Toasts/Toasts";
import ModalDelete from "../../Modal/ModalDelete";

import _dataTableFilter from "../../../utils/dataTableFilter";
import _exportData from "../../../utils/exportData";

// import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
// import { Search } from 'react-bootstrap-table2-toolkit';

const PassageTable = () => {
  // Get user role to manage his rights and access
  let role = "";
  const auth = useContext(AuthContext);
  if (auth) {
    role = auth?.role?.passage;
  }

  const [dataPassage, setDataPassage] = useState([]);
  const [dataPassageById, setDataPassageById] = useState("");
  const [passageId, setPassageId] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [filter, setFilter] = useState("");
  const [dataFiltered, setDataFiltered] = useState([]);

  // Datatable variables :
  const widthcommment = "150px";

  const [hideAction, setHideAction] = useState(false);

  const [useRefresh, setUseRefresh] = useState();

  //toasts
  const [showToasts, setShowToasts] = useState(false);
  const [contentToasts, setContentToasts] = useState("");
  const [stylesToasts, setStylesToasts] = useState("");

  const toggleShowToasts = () => {
    setShowToasts(!showToasts);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Élements par page",
    rangeSeparatorText: "de",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Tous",
    // RowsPerPageOptions={[25, 50, 100]}
  };

  const customStyles = {
    header: {
      style: {
        minHeight: "100px",
      },
    },
    headRow: {
      style: {
        borderTopStyle: "solid",
        borderTopWidth: "1px",
        borderTopColor: defaultThemes.default.divider.default,
        fontWeight: "bold",
        backgroundColor: "grey",
        color: "white",
      },
    },
    headCells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
    cells: {
      style: {
        "&:not(:last-of-type)": {
          borderRightStyle: "solid",
          borderRightWidth: "1px",
          borderRightColor: defaultThemes.default.divider.default,
        },
      },
    },
  };

  // On recupere les data en interrogeant le back end
  useEffect(() => {
    const fetchPassage = () => {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}api/pdx/passage/list`,
        withCredentials: true,
      })
        .then((res) => {
          setDataPassage(res.data);
        })
        .catch((err) => {
          console.log("No data passage", err);
        });
    };
    fetchPassage();
  }, [useRefresh]);

  // Appel vers la fonction de recherche
  useEffect(() => {
    if (filter) {
      setDataFiltered(_dataTableFilter(dataPassage, filter));
    }
    console.log("dataFiltered", dataFiltered);
  }, [filter]);

  const handleExport = () => {
    _exportData(dataFiltered || dataPassage);
  };

  // Catch data from selected passage
  // console.log(dataPassage)
  const selectedPassage = (id) => {
    setDataPassageById(dataPassage.find((e) => e.id === id));
  };

  // Construction des colonnes de la datatable
  const columns = [
    {
      selector: (row) => (
        <div>
          {/* Hide action column if user have not the autorisation */}
          {role?.page?.w === false && role?.page?.d === false
            ? setHideAction(true)
            : setHideAction(false)}
          <Row>
            {role?.page?.w === true ? (
              <Col
                variant="light"
                className="btnTable pe-3"
                onClick={(e) => {
                  e.preventDefault();
                  selectedPassage(row.id);
                  setModalShow(true);
                }}
              >
                <PencilSquare />
              </Col>
            ) : (
              ""
            )}

            {/* Delete */}
            {role?.page?.d === true ? (
              <Col
                variant="success"
                className="btnTable pe-3"
                onClick={(e) => {
                  e.preventDefault();
                  setPassageId(row.id);
                  setShowModalDelete(true);
                }}
              >
                <TrashFill />
              </Col>
            ) : (
              ""
            )}
          </Row>
        </div>
      ),
      name: "Action",
      button: true,
      width: "100px",
      center: true,
      omit: hideAction,
    },
    {
      name: <div>F/D</div>,
      selector: (row) => row.status_greffe,
      center: true,
      sortable: true,
      width: "70px",
    },
    {
      name: "Etiquette ",
      selector: (row) => row.num_etiquette,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>dA</div>,
      selector: (row) => row.diametre_a,
      center: true,
      sortable: true,
      width: "auto",
    },
    {
      name: <div>dB</div>,
      selector: (row) => row.diametre_b,
      center: true,
      sortable: true,
      width: "auto",
    },
    {
      name: <div>Vtum</div>,
      selector: (row) => (row.diametre_a * row.diametre_a * row.diametre_b) / 2,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>Caractéristiqe Hôte</div>,
      selector: (row) => row.caract_hote,
      center: true,
      sortable: true,
      width: "250px",
      wrap: true,
    },
    {
      name: <div>Dernière mesure</div>,
      selector: (row) => new Date(row?.date_greffe).toLocaleDateString("fr-FR"),
      center: true,
      sortable: true,
      width: "120px",
    },
    {
      name: "Donneur",
      selector: (row) => row.num_donneur,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: "Receveur",
      selector: (row) => row.num_hote,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Passage</div>,
      selector: (row) => row.niveau_passage,
      center: true,
      sortable: true,
      width: "90px",
    },
    {
      name: <div>ID PDX ICM</div>,
      selector: (row) =>
        (row.Modele_pdx && row.Modele_pdx["ref_modele_pdx_icm"]) || "",
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Date greffe</div>,
      selector: (row) => row?.date_greffe?.split("T")[0],
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Date sacrifice</div>,
      selector: (row) =>
        row?.date_deces
          ? new Date(row?.date_deces).toLocaleDateString("fr-FR")
          : "",
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Cause décès</div>,
      selector: (row) => row.cause_deces,
      center: true,
      sortable: true,
      width: "80px",
    },
    {
      name: <div>Durée pousse</div>,
      selector: (row) => row.duree_pousse,
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      name: <div>Caractéristique Tumeur</div>,
      selector: (row) => row.caract_tumeur,
      center: true,
      sortable: true,
      width: "130px",
      wrap: true,
    },

    {
      name: <div>Protocole</div>,
      selector: (row) => row.protocole,
      center: true,
      sortable: true,
      width: "100px",
      wrap: true,
    },
    {
      name: "Arrivée",
      selector: (row) => row.date_arrivee?.split("T")[0],
      center: true,
      sortable: true,
      width: "100px",
    },
    {
      selector: (row) => row.commentaire_passage,
      name: "Commentaire",
      center: true,
      sortable: true,
      width: widthcommment,
      wrap: true,
    },
  ];

  return (
    <>
      <Container fluid>
        {auth ? (
          <div>
            {/* Toast */}
            <div className="toastsPosition">
              <Toasts
                showToasts={showToasts}
                toggleshowToasts={toggleShowToasts}
                contentToasts={contentToasts}
                stylesToasts={stylesToasts}
              />
            </div>
            {/* Titre */}
            <div className="container">
              <div className="row">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className="title_page">LISTE DES PASSAGES</h2>
                </div>
              </div>
            </div>
            <Row>
              {/* Block pour choisir quelle colonne on veut afficher */}
              {/* <div>
                <div
                  className="col-2"
                  onClick={handleClick}
                  style={{ fontWeight: "bold" }}
                >
                  Colonnes&emsp;
                  <BsArrowDownSquareFill />
                </div>

                {isShown && (
                  <div>
                    <Form>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Actions"
                        defaultChecked
                        onChange={() => setHideAction(!hideAction)}
                      />
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Caract. Hote"
                        defaultChecked
                        onChange={() => setHideCaracH(!hideCaracH)}
                      />
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Caract. Tumeur"
                        onChange={() => setHideCaracTum(!hideCaracTum)}
                      />
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        label="Commentaire"
                        defaultChecked
                        onChange={() => setHideComment(!hideComment)}
                      />
                    </Form>
                  </div>
                )}
              </div> */}

              <Col md={2}>
                {/* Bouton d'ajout */}
                <BtnAddPassage
                  setUseRefresh={setUseRefresh}
                  showToasts={showToasts}
                  toggleShowToasts={toggleShowToasts}
                  setContentToasts={setContentToasts}
                  setStylesToasts={setStylesToasts}
                />
              </Col>
              {/* Champ de recherche */}
              <Col>
                <input
                  type="text"
                  placeholder="Rechercher..."
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Col>
              {/* Bouton export */}
              <Col>
                <Button
                  variant="info"
                  onClick={() => {
                    handleExport();
                  }}
                >
                  Exporter
                </Button>
              </Col>
            </Row>
            {/* Tableau de données */}
            <DataTable
              pagination
              paginationComponentOptions={paginationComponentOptions}
              keyField="id"
              columns={columns}
              data={filter ? dataFiltered : dataPassage}
              columnToggle
              dense={false}
              responsive={true}
              striped
              customStyles={customStyles}
              //Couleur des lignes
              rowStyle={{ backgroundColor: "red" }}
              fixedHeader={true}
            />
            {/* Appel du modal d'ajout et édition */}
            <ModalPassage
              dataPassageById={dataPassageById}
              setModalShow={setModalShow}
              modalShow={modalShow}
              setUseRefresh={setUseRefresh}
              scope={"edit"}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            />
            {/* Appel du modal de suppression */}
            <ModalDelete
              scope="deletePassage"
              id={passageId}
              setUseRefresh={setUseRefresh}
              showModalDelete={showModalDelete}
              setShowModalDelete={setShowModalDelete}
              showToasts={showToasts}
              toggleShowToasts={toggleShowToasts}
              setContentToasts={setContentToasts}
              setStylesToasts={setStylesToasts}
            />
          </div>
        ) : (
          <div>
            <Loading titleSpinner="Loading data" />
          </div>
        )}
      </Container>
    </>
  );
};

export default PassageTable;
