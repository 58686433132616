import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm p-3">
            <span>&copy;{new Date().getFullYear()} ICM - <a href="https://icm.unicancer.fr/" target="_blank">Institut du Cancer de Montpellier</a></span>
            <a href="/mentions-legales">&emsp;&emsp;Mentions légales</a>
            <a href="/credits">&emsp;&emsp;Crédits</a>
            <a href="/protection_data">&emsp;&emsp;Protection de vos données</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;