import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ModalEditPwd = (props) => {
  let url = "";
  let method = "";
  let contentToasts = "";

  const [pwd, setPwd] = useState();
  const [pwdVerif, setPwdVerif] = useState();
  const [pwdCrypt, setPwdCrypt] = useState();


  const [confirmEdit, setConfirmEdit] = useState(false);
  const [showBlock, setShowBlock] = useState(false);

  const handleClose = () => props.setShowModalEditPwd(false);

  const verifPwd = () => {};

  const handleEditPwd = async (e) => {
    // Vérification des 2 mdp saisis, si ce sont les même on crypte le premier et on modifie l'utilisateur avec ce mdp
    e.preventDefault();
    e.stopPropagation();
    if (pwd === pwdVerif) {
      console.log("mdp ok")
      // await setPwdCrypt(pwd.hashSync(pwd, 10))
      await axios({
        url: `${process.env.REACT_APP_API_URL}api/user/update/${props?.userId}`,
        method: "put",
        withCredentials: true,
        data: { user_password: pwd },
      })
      .then((res) => {
        // props.setContentToasts(contentToasts);
        // props.toggleShowToasts();
        // props.setUseRefresh(true);
        console.log("mdp changé")
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });

    } else {
      console.log('PAS LES MEME MDP')
    }

    
    // props.setShowModalEditPwd(false);
  };

  return (
    <div>
      <Modal
        className="backgroundEditPwd"
        show={props.showModalEditPwd}
        onHide={() => props.setShowModalEditPwd(false)}
        size="lg"
        centered="true"
      >
        <Modal.Body className="contentDelete">
          <span>
            Attention cette action réinitialisera le mot de passe de
            l'utilisateur !<br />
            Il devra être informé du changement pour se connecter à nouveau.
            <br />
            Voulez vous vraiment modifier ce mot de passe ? <br />
          </span>
          <br />

          {showBlock === true ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Col md={4}>
                <Row>
                  <Form.Control
                    type="password"
                    placeholder="Nouveau mot de passe"
                    defaultValue=""
                    onChange={(e) => setPwd(e.target.value)}
                    autoFocus
                  />
                </Row>
                <Row></Row>
                <Row>
                  <Form.Control
                    type="password"
                    placeholder="Confirmez le mot de passe"
                    defaultValue=""
                    onChange={(e) => setPwdVerif(e.target.value)}
                    autoFocus
                  />
                </Row>
              </Col>
            </div>
          ) : (
            <Button variant="success" onClick={() => setShowBlock(true)}>
              Modifier
            </Button>
          )}
        </Modal.Body>

        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="danger"
            onClick={() => props.setShowModalEditPwd(false)}
          >
            Annuler
          </Button>
          <Button variant="success" onClick={handleEditPwd}>
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalEditPwd;
